import React from "react";
import { Form, Button, Col, Row, DatePicker, Upload, message, Modal, Card, Input } from "antd";
import cuid from 'cuid'
import { keepTrying } from '../../shared/utilities'
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from 'react';
import moment from 'moment'
import { storage } from '../../firebase'
import './InvoicesSubForm.css'

const InvoicesSubForm = (props) => {
  
  const layout = {
      lg: 5,
      sm: 24
  };

  const rules = [{ required: true }];
  const {form, onChanged} = props;
  const initialFileList = props.fileList;
  const [indexOfUploadingPicture, setIndexOfUploadingPicture] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState(initialFileList);
  const [loading, setLoading] = useState(false);
  const [resizing, setResizing] = useState(false);
  const {disableSave} = props;

  const  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  useEffect(() => {
    setFileList(initialFileList);
    
  },[initialFileList.join(",")])

  useEffect(() => {
    if (resizing) {
      disableSave(true);
    } else {
      disableSave(false);
    }
  },[resizing])

  useEffect(() => {
    //console.log("form.getFieldValue",form.getFieldValue("invoices"))
  },[])

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle("Invoice");
    
  };
  
  const handleCancel = () => setPreviewVisible(false);

  const handleChange = ({fileList},index) => {
    console.log("Filelist",fileList) 
    console.log("index",index) 
    setFileList(prevFileList =>  {
      let newFileList = [...prevFileList];
      newFileList[index] = fileList;
      console.log("NewFileList",newFileList);
      return newFileList;
    } );
    onChanged();
  };
  
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
    }
    return isJpgOrPng && isLt10M;
  }

  const customUpload = async ({ onError, onSuccess, file, onProgress },index) => {
    setIndexOfUploadingPicture(index);
    
    const metadata = {
      contentType: file.type
    }
    const storageRef = storage.ref();
    const unique_id = cuid(); //a unique name for the image
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const imageName = 'images/' + unique_id + suffix; 
    const resizedImageName = 'images/' + unique_id + '_2500x2500' + suffix;
    const thumbImageName = 'images/' + unique_id + '_200x200' + suffix;
    const imgFile = storageRef.child(imageName);
    try {
      const uploadTask = imgFile.put(file, metadata);
      //check upload progress
      uploadTask.on('state_changed', function progress(snapshot) {
        const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
        console.log('snapshot percent', percent)
        onProgress({percent: percent })
        //start searching for resized image once upload is complete
        if (percent === 100) {
          //show uploaded file
          onSuccess({});
          
          const resizedStorageRef = storage.ref().child(resizedImageName);
          const thumbStorageRef = storage.ref().child(thumbImageName);
          const resizePromises = [keepTrying(10, resizedStorageRef),keepTrying(10, thumbStorageRef)];
          setResizing(true);
          Promise.all(resizePromises).then((results) => {
            const [resizedUrl,thumbUrl] = results;
            console.log("Resized to 2500x2500",resizedUrl);
            console.log("Resized to 200x200",thumbUrl);
            onSuccess({imageUrl: resizedUrl, imageName: resizedImageName, thumbUrl: thumbUrl, thumbName: thumbImageName});
            setResizing(false);

          })
        }
      });
      
    }
    catch(e) {
      console.log(e);
      onError(e);
    }
  };

  const uploadButton = (
    <div className="">
      <div className="ant-upload-text ">Upload</div>
  </div>
  );

  
  

 

  return (
      
    <div>
      <Form.List name="invoices">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            
            <Row gutter={[16,16]}>
              {fields.map((field, index) => (
               
                
                <Col lg={4} md={8} sm={24} key={index}>
                <Card 
                  hoverable
                  actions={[
                    <DeleteOutlined key="delete"  onClick={() => {
                      remove(field.name);
                      setFileList(prevFileList => {
                        const newFileList = [...prevFileList];
                        //newFileList[index] = [];
                        newFileList.splice(index,1);
                        return newFileList;
                      })
                    }}  />,
                  ]}
                  cover={fileList[index] && fileList[index].length >= 1 && (fileList[index][0].url) ? (
                          <img style={{height:"150px",  objectFit:"cover" }} 
                              src={fileList[index][0].url} 
                              onClick={() => handlePreview({url:fileList[index][0].url})} />
                        ) : null}  

                >

                {fileList[index] && fileList[index].length >= 1  && fileList[index][0].url ? null : (
                    <Form.Item
                      className="tight-form-item"
                      wrapperCol={{span: 24}}
                      name={[field.name, "picture"]}
                      fieldKey={[field.fieldKey, "picture"]}
                      rules={rules}
                    >
                      <Upload
                        customRequest={(params) => customUpload(params,index)}
                        listType="picture-card"
                        fileList={fileList[index]}
                        className="invoice-picture"
                        onPreview={handlePreview}
                        onChange={(fileList) => handleChange(fileList, index)}
                        beforeUpload={beforeUpload}
                      >
                        {fileList[index] && fileList[index].length >= 1  ? null : uploadButton}
                        
                        {/* {fileList.length > 0 ? <img src={fileList[0].thumbUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                      </Upload>
                    </Form.Item>
                  )
                }
                
                {loading && indexOfUploadingPicture === index ? <LoadingOutlined />  : null}
                {resizing && indexOfUploadingPicture === index ? <span><LoadingOutlined /> Resizing...</span>  : null}
                
                <Modal
                  visible={previewVisible}
                  width="80%"
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              
                <Form.Item
                  className="tight-form-item"
                  wrapperCol={{span: 24}}
                  style={{width: "100%"}}
                  name={[field.name, "date"]}
                  fieldKey={[field.fieldKey, "date"]}
                  rules={rules}
                  
                >
                  <DatePicker format="MMMM DD YYYY" style={{width: "100%"}}    />
                </Form.Item>
              </Card>
            </Col>
              ))}
              
              <Col lg={4} md={8} sm={24}>
                <Button
                  type="dashed"
                  style={{width: "100%", height:"280px"}}

                  onClick={() => {
                    let values = form.getFieldsValue();
                    values.invoices.push({date: moment()});
                    setFileList(prevFileList => [...prevFileList,[]]);
                    form.setFieldsValue(values);
                    //add();
                  }}
                >
                  <PlusOutlined /> Add Invoice
                </Button>
              
              </Col>
            </Row>
          );
        }}
      </Form.List>
    </div>

     
  
  );
};

export default InvoicesSubForm;
