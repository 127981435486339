import React, {useEffect, useState} from 'react'
import { Layout} from 'antd'
import { firestore,  serverTimestamp, storage, fb, functions } from '../firebase'
import {convertToSearchFields} from '../shared/utilities'
import moment from 'moment';


const createKeywords = name => {
    const arrName = [];
    let curName = '';
    name.split('').forEach(letter => {
      curName += letter;
      arrName.push(curName);
    });
    return arrName;
}



const deleteUnusedImagesFromStorage = async () => {
     //Delete unused pictures ----------------------------------------------------------------------------------------------
     let allImagesInFirebaseStorage = [];
     //create array of all images in Firebase Storage
     var storageRef = storage.ref();
     var listRef = storageRef.child('images');
     try {
         const list = await listRef.listAll();
         list.prefixes.forEach(function(folderRef) {
             // All the prefixes under listRef.
             // You may call listAll() recursively on them.
         });
         list.items.forEach(function(itemRef) {
             allImagesInFirebaseStorage.push(itemRef.fullPath);
         });
     } catch(error) {
         console.log(error);
     }
     console.log("All images in Firebase Storage", allImagesInFirebaseStorage);
     //Create an array of all images used in Equipment
     let allDatabaseImages = [];
     let equipmentRefs = await firestore.collection("equipment").get();
     equipmentRefs.forEach(function(doc) {
         const data = doc.data();
         if (data.picture) {
             if (data.picture.imageName) {
                 
                 allDatabaseImages.push(data.picture.imageName);
                 allDatabaseImages.push(data.picture.thumbName);
             }
             
         }
     });
     //Create an array of all images used in Parts
     let partsRefs = await firestore.collection("parts").get();
     partsRefs.forEach(function(doc) {
         //results.push({id: doc.id, ...doc.data()})
         const data = doc.data();
         if (data.invoices) {
             data.invoices.forEach(invoice => {
                 if (invoice.picture.imageName) {
                     allDatabaseImages.push(invoice.picture.imageName);
                     allDatabaseImages.push(invoice.picture.thumbName);
                 }
             })
         }
       });
       //Create an array of all images used in Workorders
     let workordersRefs = await firestore.collection("workorders").get();
     workordersRefs.forEach(function(doc) {
         //results.push({id: doc.id, ...doc.data()})
         const data = doc.data();
         if (data.invoices) {
             data.invoices.forEach(invoice => {
                 if (invoice.picture.imageName) {
                     allDatabaseImages.push(invoice.picture.imageName);
                     allDatabaseImages.push(invoice.picture.thumbName);
                 }
             })
         }
       });
     console.log("All Equipment Images", allDatabaseImages);
     let imagesToDelete = [];
     allImagesInFirebaseStorage.forEach(image => {
         if (allDatabaseImages.includes(image) === false) {
             imagesToDelete.push(image);
             storage.ref().child(image).delete()
             .then( () => {
                 console.log("Deleted Picture Successfully",image);
             })
             .catch((error) => {
                 console.log("Delete picture failed",image, error);
             })
         }
     })
     console.log("Images deleted",imagesToDelete); 
}

const callSafetyRemindersFunction = functions.httpsCallable('generateSafetyReminders');
const backupDatabase = functions.httpsCallable('backupDatabase');



const simulateCheckSafetyReminders = async () => {
    //const firestore = admin.firestore();
    let result = {};
    try {
            //this query gets a list of all equipment where date_to_issue_safety_reminder is in the past
            let equipmentRefs = await firestore.collection("equipment")
                                .where("issue_safety_reminder", "==",true)                         
                                .where("date_to_issue_reminder","<=",new Date())
                                .get();
            
            
            
            let existingWorkorderPromises = [];
            let equipment = [];
            result.numEquipmentFound = equipmentRefs.size;

            //loop through each equipment and check if a safety reminder workorder exists. If not, create one
            equipmentRefs.forEach((eq) => {
                equipment.push({...eq.data(), id: eq.id});
                existingWorkorderPromises.push(firestore.collection("workorders")
                    .where("equipment_id","==",eq.id)
                    .where("is_safety_reminder","==",true)
                    .where("date_needed","==", eq.data().next_safety_due_date)
                    .get());
            });

            let workorderQueryResults = await Promise.all(existingWorkorderPromises);
            let workorderUpdateQueries = [];
            result.workordersGenerated = [];
            workorderQueryResults.forEach((workorders, index) => {
                if (workorders.size === 0) {
                    //No workorder exists. Create one.
                    
                    let newWorkorder = {equipment_name: equipment[index].name,
                                        equipment_id: equipment[index].id,
                                        equipment: { name: equipment[index].name,
                                                    id: equipment[index].id,
                                                    category: equipment[index].category,
                                                    unit_number: equipment[index].unit_number,
                                                    picture: equipment[index].picture},
                                        is_safety_reminder: true,
                                        order_date: serverTimestamp(),
                                        date_needed: equipment[index].next_safety_due_date,
                                        request_description: "Safety Due",
                                        priority_level: "30",
                                        search_fields: convertToSearchFields([equipment[index].name, equipment[index].unit_number]),
                                        completed: false,
                                        completed_date: null,
                                        created: serverTimestamp(),
                                        updated: serverTimestamp(),
                                        user_id: null,
                                        requestor_name: 'Automatically Generated',
                                        description_of_work_completed: null,
                                        explanation_of_incomplete_work: null,
                                        completed_by: null,
                                        parts: [],
                                        invoices: [] 
                                    }
                    //workorderUpdateQueries.push(firestore.collection('workorders').add({...newWorkorder}));
                    console.log("Creating safety reminder workorder for: ",equipment[index].name);
                    result.workordersGenerated.push(newWorkorder);
                   
                } else {
                    console.log("Workorder already exists");
                }

            })

            //let workorderUpdateResults = await Promise.all(workorderUpdateQueries);

                        
            
            return result;
            console.log("result",result);
            
            
        } catch(error) {
            console.log(error.message);
            return error.message;
            
        }

}


function RunFireStoreUpdate() {
    const {Content} = Layout;
    //const [results, setResults] = useState([]);

    const updateToRun = async () => {
        
        //simulateCheckSafetyReminders();
        
        //deleteUnusedImagesFromStorage();

        //generateSafetyReminders();

        //callSafetyRemindersFunction().then(res => console.log(res.data)).catch(error => console.log(error));
        //backupDatabase().then(res => console.log(res.data)).catch(error => console.log(error));

        
        //Add search_fields to equipment for searching---------------------------------------------------------------------------
        /* firestore.collection("equipment")
        .get()
        .then((querySnapshot) => { 
            querySnapshot.forEach(function(doc) {
                console.log(doc.data().name);
                firestore.collection("equipment").doc(doc.id).set({search_fields: convertToSearchFields([doc.data().name, doc.data().unit_number])}, {merge: true})
                .then(() => {
                    const message = "Equipment: " + doc.data().name + " updated ";
                    console.log(message);
                    //setResults()
                    return true;
                  })
                  .catch((error) => {
                      console.error("Error updating document: ", error);
                  });
            }); 
        }) */

        
        //Add created to parts --------------------------------------------------------------------------------------------------
        /* firestore.collection("parts")
        .get()
        .then((querySnapshot) => { 
            querySnapshot.forEach(function(doc) {
                firestore.collection("parts").doc(doc.id).set({created: serverTimestamp()}, {merge: true})
                .then(() => {
                    const message = "Part: " + doc.data().description + " updated ";
                    console.log(message);
                    //setResults()
                    return true;
                  })
                  .catch((error) => {
                      console.error("Error updating document: ", error);
                  });
            }); 
        }) */

       /*  var equipment = firestore.collectionGroup('parts_used').where('description', '==', 'subtest');
            equipment.get().then(function (querySnapshot) {
                
                querySnapshot.forEach(function (doc) {
                    console.log(doc.id, ' => ', doc.data());
                    console.log("parent",doc.getParent());
                });
            }); */

       
       
        //Add parts_ids to equipment---------------------------------------------------------------------------
        /* firestore.collection("equipment")
        .get()
        .then((querySnapshot) => { 
            querySnapshot.forEach(function(doc) {
                let parts_ids = doc.data().parts.map(part => part.part_id);
                
                firestore.collection("equipment").doc(doc.id).set({parts_ids: parts_ids}, {merge: true})
                .then(() => {
                    const message = "Part: " + doc.data().description + " updated ";
                    console.log(message);
                    //setResults()
                    return true;
                  })
                  .catch((error) => {
                      console.error("Error updating document: ", error);
                  });
            }); 
        })     */


        
       
       
    }

    useEffect(() => {
        updateToRun();
      },[])




    return (
        <div>
      
        
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          
        </Content>
            
        </div>
    )
}

export default RunFireStoreUpdate
