import React, {useState, useEffect} from 'react';
import { Layout, Menu, message} from 'antd';
import { UserOutlined, ToolOutlined, CarOutlined, AppstoreAddOutlined, LogoutOutlined } from '@ant-design/icons';
import logo from './assets/logo.png'
import { Route, Switch, Redirect, useHistory} from 'react-router-dom'
import ListWorkorders from './Workorders/ListWorkorders/ListWorkorders'
import ListEquipment from './Equipment/ListEquipment/ListEquipment'
import ListUsers from './Users/ListUsers/ListUsers'
import ListParts from './Parts/ListParts/ListParts'
import RunFireStoreUpdate from './Admin/RunFireStoreUpdate'
import EquipmentForm from './Equipment/EquipmentForm/EquipmentForm'
import WorkorderForm from './Workorders/WorkorderForm/WorkorderForm'
import PartForm from './Parts/PartForm/PartForm'
import UserForm from './Users/UserForm/UserForm'
import Login from './Login/Login'
import './App.css';
import MenuItem from 'antd/lib/menu/MenuItem';
import { auth, firestore } from './firebase';


function App() {
  const { Header, Sider } = Layout;
  const [currentUser,setCurrentUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const history = useHistory();
  const signOut = () => {
    setCurrentUser(null);
    auth.signOut()
    .then(() => {
      console.log('Signed Out');
      history.push('/');
    })
    .catch(e=>{
     console.error('Sign Out Error', e);
    });
    
  }

  const getAuthenticatedUser = (id) => {
    //console.log("user id",id);
    firestore.collection("users").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                setCurrentUser(data);
                //console.log("User:",data);
                setCheckingAuth(false);
              } else {
                console.log("No such document!");
                message.error("Error getting User Data!");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Part");
            });
  }
  
  useEffect(() => auth.onAuthStateChanged((user) => {
    if (user) {
      //console.log("Signed In",user);
      if (!currentUser) {
        getAuthenticatedUser(user.uid);
      }
     
    } else {
      //console.log("Not signed in");
      setCurrentUser(null);
      setCheckingAuth(false);
    }
  }),[]);

  let login = (<Login />);
  if (checkingAuth) {
    login = null;
  }
  

  let adminRoutes = null;
  if (currentUser && currentUser.role === 'admin') {
    adminRoutes = ([<Route key="users" path="/users" exact component ={ListUsers} />,
                    <Route key="usersnew" path="/users/new" exact component ={UserForm} />,
                    <Route key="usersid" path="/users/:id" component ={UserForm} />])
  }
  return (
    <div className="App">
      {currentUser && !checkingAuth ? 
        <Layout style={{height:"100vh"}} >
          <Header className="header no-print">
          <img className="logo" src={logo} style={{height: "40px"}} alt="Equipment Maintenance"/>
            <Menu  theme="dark" mode="horizontal" style={{position: 'absolute', top: 0, right: 0}}>
            <MenuItem key="currentuser"><UserOutlined />{currentUser.first_name}</MenuItem>
              <MenuItem key="signout" onClick={signOut}><LogoutOutlined />Sign Out</MenuItem>
              
            </Menu>
          </Header>
        <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="no-print"
        onBreakpoint={broken => {
          //console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          //console.log(collapsed, type);
        }}
      >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[history.location.pathname.split('/')[1] || 'workorders']}
            
            style={{ height: '100%', borderRight: 0 }}
          >
            
              <Menu.Item key="workorders" onClick={() => history.push('/workorders')}> <ToolOutlined />Workorders </Menu.Item>
              <Menu.Item key="equipment" onClick={() => history.push('/equipment')}><CarOutlined />Equipment</Menu.Item>
              <Menu.Item key="parts" onClick={() => history.push('/parts')}> <AppstoreAddOutlined />Parts</Menu.Item>
              { currentUser && currentUser.role === 'admin' ? 
                <Menu.Item key="users" onClick={() => history.push('/users')}><UserOutlined />Users</Menu.Item>
              :
              null
              }
            
        
          </Menu>
        </Sider>
        <Layout className="main-layout" style={{}}>
        <Switch>
           
            <Route path="/equipment" exact component ={ListEquipment} />
            <Route path="/equipment/new" exact component ={EquipmentForm} />
            <Route path="/equipment/:id" component ={EquipmentForm} />
            <Route path="/workorders" exact component={ListWorkorders} />
            <Route path="/workorders/new" exact component ={WorkorderForm} />
            <Route path="/workorders/:id" component ={WorkorderForm} />
            <Route path="/parts" exact component ={ListParts} />
            <Route path="/parts/new" exact component ={PartForm} />
            <Route path="/parts/:id" component ={PartForm} />
            {adminRoutes}
                
            
            <Route path="/runfirestoreupdate" component ={RunFireStoreUpdate} />

            <Route path="/" exact component={ListWorkorders} />
            <Redirect to="/" />
        </Switch>
          
        </Layout>
      </Layout>
    </Layout>
      :
      login
        
        
      }
    
      
      
    </div>
  );
}

export default App;
