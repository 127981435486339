import React, {useEffect, useState, useRef} from 'react'
import {Breadcrumb, Layout, PageHeader, Button, Avatar, message, Popconfirm, Table, Input, Row, Col, Space, Tooltip, Select} from 'antd'
import { PlusOutlined, CarOutlined, LeftOutlined, RightOutlined, PrinterOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import { firestore, storage } from '../../firebase'
import {debounce} from '../../shared/utilities'
import './ListEquipment.css'





function ListEquipment() {
  
  
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  //const resultsPerPage = 20;
    
  //state
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ searchText, setSearchText] = useState('');
  const [ tableParams, setTableParams] = useState({});
  const [ sortOrder, setSortOrder] = useState(null);
  const [ lastDocRef, setLastDocRef] = useState(null);
  const [ firstDocRef, setFirstDocRef] = useState(null);
  const [ prevDisabled, setPrevDisabled] = useState(true);
  const [ nextDisabled, setNextDisabled] = useState(false);
  const [ currentPage, setCurrentPage] = useState(1);
  const [ totalEquipment, setTotalEquipment] = useState(0);
  const [printPage, setPrintPage] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(20);
  

  let unsubscribeFromTotalEquipment = null;

    
  const onCellClick = (record) => {
    
    return {
      onClick: event => { history.push({
        pathname: `/equipment/${record.id}`,
        state: record
      })}
    }
  }

  const fetch = async (params = {}, searchTextToUse = null, movePage = null) => {
      
    if (searchTextToUse) {
      searchTextToUse = searchTextToUse.toLowerCase();
    }

    

    //console.log("fetch params",params);
    
    setTableParams(params);
    let sortField = params.sortField ? params.sortField : "name";
    let sortOrder = 'asc';
    let results = [];
    setLoading(true);
    
    try {
      let refs = null;
      let startAfter = '';
      //let limit = 10;
      if (movePage === "next") {
        startAfter = lastDocRef;
      }

      let query = firestore.collection("equipment").orderBy(sortField, sortOrder).limit(resultsPerPage)
      if (searchTextToUse) {
        query = query.where('search_fields', 'array-contains', searchTextToUse);
      }

      
      if (movePage === "next" || !movePage) {
      //Moving to next page or getting first page
        if (startAfter) {
          query = query.startAfter(startAfter);
        }
      } else {
      //Moving to Previous Page
        query = query.endBefore(firstDocRef).limitToLast(resultsPerPage)
      }
      refs = await query.get();
      
      if (refs.docs[refs.docs.length -1] ) {
        setLastDocRef(refs.docs[refs.docs.length -1]);
      } 
      
      if (refs.docs[0]) {
        setFirstDocRef(refs.docs[0]);
      } else {
        //no results on this page, set First Doc to Last Doc of previous page for moving backwards at the end
        setFirstDocRef(lastDocRef);
      }
     
      refs.forEach(function(doc) {
        results.push({id: doc.id, ...doc.data()})
      });
        
      setData(results);

      let page = currentPage;
      if (params.page) {
        page = params.page;
        console.log("does this ever run???");
      }
      
      if (results.length < resultsPerPage) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }

      
      if (movePage === 'next') {
        page = currentPage+1;
      }
      console.log("page",page,"resultsPerPage",resultsPerPage, "totalequipment",totalEquipment);
      if (page*resultsPerPage === totalEquipment) {
        console.log("disabling next");
        setNextDisabled(true);
      }

      if (movePage === 'next') {
        setPrevDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage + 1 );
      }

      if (movePage === 'previous') {
        if (currentPage === 2) {
          setPrevDisabled(true);
        }
        setNextDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage - 1 );
      }
     
      setLoading(false);

    } catch(error) {
      console.log("Error getting documents: ", error);
    }
  };

  const { current: fetchEquipment } = useRef(fetch);

  const subscribeToTotalEquipment = () => {
    unsubscribeFromTotalEquipment = firestore.doc("counters/equipment").onSnapshot((doc) => {
      if (doc.data()) {
        console.log("Total Equipment",doc.data().count);
        setTotalEquipment(doc.data().count);
        
      } else {
        setTotalEquipment(0);
      }
      
    })
  }

  useEffect(() => {
    if (currentPage*resultsPerPage === totalEquipment) {
      console.log("disabling next");
      setNextDisabled(true);
    }
  },[totalEquipment])

  const { current: sub } = useRef(subscribeToTotalEquipment);
  //const { current: unsub } = useRef(unsubscribeFromTotalEquipment);
  
   useEffect(() => {
      //console.log("Running useEffect",data);
      sub();
      //fetchEquipment();
      return () => {
        unsubscribeFromTotalEquipment();
      }

    
  },[sub,fetchEquipment]) 


  useEffect(() => {
    console.log("useEffect for resultsPerPage change",resultsPerPage);
    let params = tableParams;
    params.page = 1;
    setCurrentPage(1);
    setPrevDisabled(true);
    fetch(params, searchText);
  },[resultsPerPage])

  const columns = [
    {
      title: 'Pic',
      dataIndex: 'picture',
      width: 80,
      render: (text, record) => { if (record.picture) {
          return <Avatar  size={64} src={record.picture.thumbUrl} />
          } else {
            return <Avatar  size={64} />;
        }
      },
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
      
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: 175,
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
    {
      title: 'Unit #',
      dataIndex: 'unit_number',
      width: 75,
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
      
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      width: 150,
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
    
    {
      title: 'Year',
      dataIndex: 'year',
      width: 50,
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      width: 100,
      render: (text, record) => {
        switch (record.condition) {
          case '10 Good':
            return ('Good');
          case '20 Fair':
            return ('Fair');
          case '30 Poor':
            return ('Poor');
          case '40 Out of Order':
            return ('Out of Order');
          default:
            return "";
        }
      },
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
    {
      title: 'Action',
      
      className: "no-print right-cell",
      render: (text, record, index) => {
        return (
          <Popconfirm
            title={"Delete Equipment: " + record.name + "?"}
            onConfirm={() => deleteEntry(record.id, record.picture)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
            
          </Popconfirm>
        )
      }
    }
  ];

    const deleteEntry = (id,picture) => {
      console.log("picture",picture);
      
      //Delete Database Entry
      firestore.collection("equipment").doc(id).delete()
      .then(() => {
        console.log("Document successfully deleted!");
        message.success('Equipment Deleted');
       
        //Delete Picture
        if (picture) {
          storage.ref().child(picture.imageName).delete()
            .then( () => {
              console.log("Deleted Picture Successfully")
            })
            .catch((error) => {
              console.log("Delete picture failed",error);
            })

        }
        fetch();
      }).catch(function(error) {
          console.error("Error removing document: ", error);
          message.error('Error deleting Equipment ');
      });
    }
    
    const cancel = (e) => {
      console.log(e);
      
    }

    const handleTableChange = (pag, filters, sorter) => {
      //const pager = { ...pagination };
      //pager.current = pag.current;
      setCurrentPage(1);
      setPrevDisabled(true);
      //setPagination({...pager},
        fetch({
         /*  results: pag.pageSize,
          page: pag.current, */
          page: 1,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
          
        },searchText)
        
      //  );
      
      
      
    };

    const onTextSearch = debounce((value) => {
           //console.log("onKeystroke",value);
           setCurrentPage(1);
           setPrevDisabled(true);
           setSearchText(value, setCurrentPage(1,fetch(tableParams,value)) );
           //fetch(tableParams,value);
    },200)


    const printButtonChild = <Button  key='print' className='no-print' onClick={() => {setPrintPage(true)}}><PrinterOutlined /></Button>;
      let printButton = (<Tooltip key='printtooltip' className='no-print' title="Print Equipment List" >
                          {printButtonChild}  
                        </Tooltip>);
      //don't render tooltip when printing
      if (printPage) {
        printButton = printButtonChild;
      }

      useEffect(() => {
        if (printPage) {
          window.print();
          setPrintPage(false);
        }
      },[printPage]);
  


    return (
        <div >
          <Breadcrumb className="no-print">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Equipment</Breadcrumb.Item>
            
          </Breadcrumb>
       
        
        <Content className="site-layout-background">
          
       
            <PageHeader
            className="site-page-header-responsive"
            avatar={{icon: <CarOutlined />}}
            ghost={false}
            onBack={() => window.history.back()}
            title="Equipment"
            
            backIcon={false} 
            extra={[
               
              <Row gutter={[8,8]} key="extra_row">
                <Col lg={12} sm={24} key="search">
                    <Search
                    placeholder="search by name or unit"
                    block="true"
                    className="no-print"
                    //style={{ width: 200 }}
                    key="search"
                    onChange={(e) => onTextSearch(e.target.value)}
                  />
                </Col>
                <Col lg={8} sm={24} key="create_new">
                  <Button key="/" className="no-print" type="primary" block="true" onClick={() => {history.push("/equipment/new")}}>
                  <PlusOutlined />Create New
                  </Button>
                </Col>
                <Col lg={2} sm={24}>
                  {printButton}
                </Col>
                
               
              </Row>
              
            ]}
            >
            
            </PageHeader>

            <Table
              columns={columns}
              className="ant-table"
              //scroll={{x: 'calc(100vh - 4em)'}}
              scroll={{ x: 'max-content' }}
              rowKey={record => record.id}
              sortDirections={['ascend']}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              size="small"
              
            />
            <Row gutter={[8,8]} align="middle" justify="end" style={{marginTop: '16px'}} >
              <Col >
                <span>
                  { searchText ? null : (currentPage-1)*resultsPerPage+1 + " to " + (totalEquipment < currentPage*resultsPerPage ? totalEquipment : currentPage*resultsPerPage ) + " of " + totalEquipment + " Total Entries"}
                </span>
              </Col>
              <Col >
                <Space className="no-print">
                  <Button disabled={prevDisabled} key="previous" type="default" onClick={() => fetch(tableParams, searchText, "previous")}>
                      <LeftOutlined />Previous
                    </Button>
                  
                  <Button disabled={nextDisabled} key="next" type="default" onClick={() => fetch(tableParams, searchText, "next")}>
                      <RightOutlined />Next
                  </Button>
                  <Select style={{width: 110}} defaultValue={resultsPerPage} onChange={(value) => setResultsPerPage(value)}>
                    <Select.Option value={20}>20 / page</Select.Option>
                    <Select.Option value={50}>50 / page</Select.Option>
                    <Select.Option value={100}>100 / page</Select.Option>
                  </Select>
                </Space>
              </Col> 
                
                
              
            </Row>

        </Content>
            
        </div>
    )
}

export default ListEquipment
