import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, DatePicker, Tooltip, Badge, message, Divider, Select, AutoComplete, List, Avatar } from 'antd';
import { useHistory, useParams, useLocation, Prompt } from 'react-router-dom'
import {SaveOutlined, CloseOutlined, PrinterOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp, fb, getCurrentUser } from '../../firebase'
import Moment from 'moment';
import PartsSubForm from '../../PartsSubForm/PartsSubForm'
import InvoicesSubForm from '../../Parts/InvoicesSubForm/InvoicesSubForm'
import {convertToSearchFields,debounce, updateEquipmentParts } from '../../shared/utilities'
import './WorkorderForm.css'
import moment from 'moment';






function WorkorderForm(props) {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { Option } = Select;
    const { id } = useParams();
    const location = useLocation();
    const [title, setTitle] = useState('New Workorder');
    const [fileList, setFileList] = useState([]);
    const [formChanged, setFormChanged] = useState(false);
    const [originalPicture, setOriginalPicture] = useState(null);
    const [equipmentSearchResults, setEquipmentSearchResults] = useState([]);
    const [equipmentDetails, setEquipmentDetails] = useState(null);
    const [equipmentSearchDisabled, setEquipmentSearchDisabled] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [printPage, setPrintPage] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const disableSave = (isDisabled) => {
      setSaveDisabled(isDisabled);
    }
    
    
    const onSearch = debounce((searchText) => {
        let searchResults = [];
        if (searchText) {
            searchText = searchText.toLowerCase();
            console.log("Searching");
            firestore.collection('equipment')
            /* .orderBy('description_lowercase', "asc")
            .startAt(searchText)
            .endAt(`${searchText}\uf8ff`) */
            .where('search_fields', 'array-contains', searchText)
            .limit(5)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function(doc) {
                    const equipment_id = doc.id;
                    const record = doc.data();
                    searchResults.push({value: record.name, 
                                        key: record.name, 
                                        label: (
                                                 <List.Item>
                                                    <List.Item.Meta
                                                    avatar={<Avatar src={record.picture ? record.picture.thumbUrl : null} />}
                                                    title={record.name}
                                                    className="equipment-list-item"
                                                    description={record.category + (record.unit_number ? " Unit #" + record.unit_number : "")   }
                                                    
                                                    />
                                                </List.Item>
                                                
                                                
                                        ), 
                                        equipment: {name: record.name, id: equipment_id, category: record.category, unit_number: record.unit_number, picture: record.picture} })
                });
                setEquipmentSearchResults(searchResults);
            })
        } else {
            setEquipmentSearchResults([]);
        }
    },200);

    
    const onSelect = (data,option,index) => {
        let values = form.getFieldsValue();
        values.equipment_name = option.equipment.name;
        values.equipment_id = option.equipment.id;
        
        setEquipmentDetails(option.equipment);
        setEquipmentSearchDisabled(true);
        form.setFieldsValue(values);
    };
    
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };

      
      

      useEffect(() => {
        getCurrentUser().then(curUser => setCurrentUser(curUser.uid));
        
        //Get document from Firebase if id exists as a param
        if (id) {
          
          if (location.state) {

            console.log("location.state",location.state);
            const data = location.state;
            setTitle(data.equipment_name);
            delete data.id;
            if (data.order_date) {
                data.order_date = Moment.unix(data.order_date.seconds);
            }
            if (data.date_needed) {
                data.date_needed = Moment.unix(data.date_needed.seconds);
            }
            if (data.completed_date) {
                data.completed_date = Moment.unix(data.completed_date.seconds);
            }

            data.created = Moment.unix(data.created.seconds);

            if (data.invoices) {
                data.invoices.forEach((invoice, index) => {
                  if (invoice.date) {
                    invoice.date = Moment.unix(invoice.date.seconds);
                  }
                  if (invoice.picture && invoice.picture.thumbUrl) {
                    setFileList(prevFileList =>  {
                      let newFileList = [...prevFileList];
                      newFileList[index] = [{
                        uid: invoice.picture.imageName,
                        name: "Invoice Photo",
                        status: 'done',
                        url: invoice.picture.imageUrl
                      }];
                     
                      return newFileList;
                    } );
                  }
                })
              }

              if (data.equipment) {
                  setEquipmentDetails(data.equipment);
                  setEquipmentSearchDisabled(true);
              }
            
            form.setFieldsValue(data);
            
          } else {
            console.log("Fetching from Firestore");
            firestore.collection("equipment").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                
                if (data.order_date) {
                    data.order_date = Moment.unix(data.order_date.seconds);
                }
                if (data.date_needed) {
                    data.date_needed = Moment.unix(data.date_needed.seconds);
                }
                if (data.completed_date) {
                    data.completed_date = Moment.unix(data.completed_date.seconds);
                }
                
                data.created = Moment(data.created.toDate());
                
                form.setFieldsValue(data);
                setOriginalPicture(data.picture);
                setTitle(data.equipment_name);
                console.log("Setting Field Values:",data);
                 
                

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                    message.error("Could not load Equipment");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
          }
          

 

        } //if (id)
      },[id,form,location.state])

     

      const onFinish = values => {
        
        console.log('Values before picture:', values);
        if (values.equipment_name) {
          values.search_fields = convertToSearchFields([values.equipment_name, equipmentDetails.unit_number]);
        }

        
        values.user_id = currentUser;
        values.updated = serverTimestamp();

        
        
        if (equipmentDetails) {
            values.equipment = equipmentDetails;
            values.equipment_id = equipmentDetails.id
        } else {
          values.equipment = null;
          values.equipment_id = null;
          values.equipment_name = null;
        }

        if (values.invoices) {
            values.invoices.forEach(invoice => {
              if (invoice.date) {
                invoice.date = fb.firestore.Timestamp.fromDate(invoice.date.toDate());
              }
  
              if (invoice.picture) {
                if (invoice.picture.file) {
                  invoice.picture = invoice.picture.file.response;
                }
              }
  
              if (typeof(invoice.picture) === 'undefined') {
                invoice.picture = null;
              }
            })
          }
        
       
        console.log("FileList",fileList);
        
        

        if (values.order_date) {
          values.order_date = fb.firestore.Timestamp.fromDate(values.order_date.toDate());
        }
        if (values.date_needed) {
          values.date_needed = fb.firestore.Timestamp.fromDate(values.date_needed.toDate());
        }
        if (values.completed_date) {
          values.completed_date = fb.firestore.Timestamp.fromDate(values.completed_date.toDate());
          values.completed = true;
        } else {
          values.completed = false;
        }
        

        //Handle Parts
        //values contains form data to be submitted
        let partsDocRefs = [];
        let partsPromises = [];
        
        if (values.parts) {
          values.parts.forEach((part,index) => {
             //If this part was hand typed (ie NOT populated from the autocomplete) we need to create a part in the parts collection and insert the id into this part
            if (!part.part_id) {
              //Get a reference to a new parts document
              partsDocRefs[index] = firestore.collection('parts').doc();
              console.log("ID from firestore for part:",partsDocRefs[index].id);
              values.parts[index].part_id = partsDocRefs[index].id;
             
              //can't do this right now, gives Firestore error. We are setting this in a onCreate hook instead
              //values.parts[index].created = serverTimestamp();
              part.search_fields = convertToSearchFields([part.description]);
              part.created = fb.firestore.Timestamp.fromDate(new Date());
              partsPromises.push(partsDocRefs[index].set({...part}));
            } 

          }) //end loop
          
          if (partsPromises.length > 0) {
            Promise.all(partsPromises)
            .then(() => {
              console.log("Parts promises finished");
              sendToFirebase(values)
            })
            .catch((e) => {
              console.log("Error creating Parts",e);
          });
          } else {
            sendToFirebase(values);
          }
          
        } else {
          sendToFirebase(values);
        }
       

        
       
        
        
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        console.log("Values before posting to Firebase",values)
        //create parts_ids array
        let parts_ids = values.parts.map(part => part.part_id);
        values.parts_ids = [...parts_ids];
        
        if (id) {
          //Update existing workorder
          firestore.collection('workorders').doc(id).set({...values}, {merge: true})
          .then(function() {
            setFormChanged(false);
            console.log("Document successfully updated!");
            message.success('Workorder Updated!');
            history.push('/workorders');
          })
          .catch(function(error) {
              console.error("Error updating document: ", error);
              message.error(error);
          });

        } else {
          //Add new workorder
          values.created = serverTimestamp();
          firestore.collection('workorders').add({...values})
          .then(function() {
            console.log("Document successfully created!");
            setFormChanged(false);
            message.success(`Added Workorder for: ${values.equipment_name}`);
            history.push('/workorders');
          })
          .catch(function(error) {
              console.error("Error creating document: ", error);
          });
        }

        if (values.equipment_id && values.parts.length > 0) {
          updateEquipmentParts(values.parts, values.equipment_id);
        }

      }

      
      const onValuesChange = () => {
        console.log("OnValuesChange triggered");
        setFormChanged(true);
      }

      const removeEquipment = () => {
        setEquipmentDetails(null);
        setEquipmentSearchDisabled(false);
        let values = form.getFieldsValue();
        values.equipment_name = null;
        values.equipment_id = null;
        values.equipment = null;
        form.setFieldsValue(values);
        setFormChanged(true);
      }

     let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" className="no-print" disabled={saveDisabled} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }

      const printButtonChild = <Button  key='print' className='no-print' onClick={() => {setPrintPage(true)}}><PrinterOutlined /></Button>;
      let printButton = (<Tooltip key='printtooltip' className='no-print' title="Print Workorder" >
                          {printButtonChild}  
                        </Tooltip>);
      //don't render tooltip when printing
      if (printPage) {
        printButton = printButtonChild;
      }
      
      useEffect(() => {
        if (printPage) {
          window.print();
          setPrintPage(false);
        }
      },[printPage]);
      
    return (
        <div className=""  > 
        <Prompt when={formChanged} message="Unsaved Changes.  Press Ok to discard edits. Press Cancel to keep editing." />
        <Breadcrumb className="no-print">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Workorders</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'Workorder: ' + title}
                extra={[
                    saveButton,
                    <Button key='close' type='default' onClick={() => window.history.back()} style={{minWidth: '125px'}} className="no-print"> 
                        <CloseOutlined />Close
                    </Button>,
                    printButton
                    
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="medium"
            layout="horizontal"
            initialValues={{ 
              requestor_name: null,
              priority_level: null,
              equipment_name: null,
              equipment_id: null,
              equipment: null,
              order_date: moment(),
              date_needed: null,
              completed_date: null,
              request_description: null,
              description_of_work_completed: null,
              explanation_of_incomplete_work: null,
              completed_by: null,
              invoice_number: null,
              mileage: null,

              
              parts: [],
              invoices: []}}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} md={12} lg={12} >
                        
                        <Form.Item className="tight-form-item" label="For Equipment" name="equipment_name">
                          { equipmentSearchDisabled ?
                            <List.Item style={{padding: '5px', border: '1px solid #D9D9D9'}} actions={[<CloseOutlined className="dynamic-delete-button" onClick={removeEquipment}/>]}>
                                    
                                <List.Item.Meta
                                avatar={<Avatar src={equipmentDetails.picture? equipmentDetails.picture.thumbUrl : null} />}
                                title={equipmentDetails.name}
                                
                                description={equipmentDetails.category + (equipmentDetails.unit_number ? " Unit #" + equipmentDetails.unit_number : "")   }
                                
                                />
                            </List.Item>
                          :
                            <AutoComplete
                              options={equipmentSearchResults}
                              //onChange={() => setRowSearching(e)}
                              //disabled={equipmentSearchDisabled}
                              
                              onSelect={(data,option) => onSelect(data,option)}
                              onSearch={(text) => onSearch(text)}
                              placeholder="equipment name or unit #"
                              style={{minWidth: '164px'}}
                              
                            />
                          }
                        </Form.Item>
                        
                       
                        
                        
                        <Form.Item
                            label="Requestor Name"
                            name="requestor_name"
                            className="tight-form-item"
                            rules={[
                            {
                                required: true,
                                message: 'Please input a name',
                            },
                            
                            ]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Priority Level" name="priority_level" rules={[{required: true, message: 'Please select a priority level'}]}>
                          <Select
                            placeholder="Select Priority Level"
                            allowClear
                          >
                            <Option value="30">
                              <div>
                                <Badge color='green' /> low
                              </div>
                            </Option>
                            <Option value="20">
                              <div>
                                <Badge color='yellow' /> medium
                              </div>
                            </Option>
                            <Option value="10">
                              <div>
                                <Badge color='red' /> high
                              </div>
                            </Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                            label="Mileage(km)/Hours"
                            name="mileage"
                            className="tight-form-item"
                            rules={[
                            {
                                required: true,
                                message: 'Please enter equipment mileage or hours',
                            },
                            
                            ]}
                            
                        >
                            <Input />
                        </Form.Item>
                       
                        
                    </Col>
                    <Col sm={24} md={12} lg={12} >
                       
                    <Form.Item className="tight-form-item" label="Order Date" name="order_date" rules={[{required: true, message: 'Please select an Order Date'}]} ><DatePicker style={{width: '100%'}} format="MMMM DD YYYY" /></Form.Item>
                    <Form.Item className="tight-form-item" label="Date Needed" name="date_needed" rules={[{required: true, message: 'Please select a Date Needed'}]} ><DatePicker style={{width: '100%'}}format="MMMM DD YYYY" /></Form.Item>
                    <Form.Item className="tight-form-item" label="Completed Date" name="completed_date" ><DatePicker style={{width: '100%'}} format="MMMM DD YYYY" /></Form.Item>
                    <Form.Item className="tight-form-item" label="Completed By" name="completed_by"><Input placeholder="Work Completed By" /></Form.Item>
                    <Form.Item className="tight-form-item" label="Invoice #" name="invoice_number"><Input placeholder="Invoice #" /></Form.Item>
                    
                       
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    <Col span={24}>
                        <Form.Item className="tight-form-item" label="Request Description" name="request_description"><Input.TextArea autoSize={{ minRows: 2 }} style={{height:"50px"}} /></Form.Item>
                        <Form.Item className="tight-form-item" label="Description Of Work Completed" name="description_of_work_completed"><Input.TextArea autoSize={{ minRows: 2 }} style={{height:"50px"}}  /></Form.Item>
                        <Form.Item className="tight-form-item" label="Explanation Of Incomplete Work" name="explanation_of_incomplete_work"><Input.TextArea autoSize={{ minRows: 2 }} style={{height:"50px"}} /></Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left">Parts Used</Divider>
                <Row>
                  <Col sm={24} lg={24}>
                    <PartsSubForm form={form} />
                  </Col>
                  
                </Row>

               {/*  <div className="no-print">
                  <Divider orientation="left">Invoices</Divider>
                  <Row>
                    <Col sm={24} lg={24}>
                      <InvoicesSubForm form={form} onChanged={() => setFormChanged(true)} fileList={fileList} disableSave={disableSave} />
                    </Col>
                    
                  </Row>
                </div> */}
                

            </Form>
        </Content>
            
        </div>
    )
}

export default WorkorderForm


