import React, {useEffect, useState, useRef} from 'react'
import {Breadcrumb, Layout, PageHeader, Button, Avatar, message, Popconfirm, Table, Input, Row, Col, Space, Badge, Tag, Select, Tooltip} from 'antd'
import { PlusOutlined, LeftOutlined, RightOutlined, ToolOutlined, CheckCircleOutlined, SafetyOutlined, PrinterOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import { firestore, storage, functions } from '../../firebase'
import {debounce} from '../../shared/utilities'
import './ListWorkorders.css'





function ListWorkorders() {
  
  
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  
  const callSafetyRemindersFunction = functions.httpsCallable('generateSafetyReminders');
    
  //state
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ searchText, setSearchText] = useState('');
  const [ tableParams, setTableParams] = useState({});
  const [ sortBy, setSortBy] = useState();
  const [ lastDocRef, setLastDocRef] = useState(null);
  const [ firstDocRef, setFirstDocRef] = useState(null);
  const [ prevDisabled, setPrevDisabled] = useState(true);
  const [ nextDisabled, setNextDisabled] = useState(false);
  const [ currentPage, setCurrentPage] = useState(1);
  const [ totalItems, setTotalItems] = useState(0);
  
  const [ printPage, setPrintPage] = useState(false);
  //const [ tableFilter, setTableFilter] = useState('all');
  //const [ resultsPerPage, setResultsPerPage ] = useState(20);
  const [ tableViewSettings, setTableViewSettings] = useState({resultsPerPage: 20, filter: 'all'});
  

  let unsubscribeFromTotalWorkorders = null;

    
  const onCellClick = (record) => {
    
    return {
      onClick: event => { history.push({
        pathname: `/workorders/${record.id}`,
        state: record
      })}
    }
  }

  const fetch = async (params = {}, searchTextToUse = null, movePage = null) => {
      
    console.log("Fetching Workorders");
    if (searchTextToUse) {
      searchTextToUse = searchTextToUse.toLowerCase();
    }
   //console.log("fetch params",params);
    
    setTableParams(params);
    let sortField = params.sortField ? params.sortField : "date_needed";
    setSortBy(sortField);
    let sortOrder = 'asc';
    if (sortField === 'order_date' || sortField === 'date_needed') {
      sortOrder = 'desc';

    }
    setLoading(true);
    let results = [];
    try {
      let refs = null;
      
      let startAfter = '';
      //let limit = 10;
      if (movePage === "next") {
        startAfter = lastDocRef;
      }

      console.log("SortField:",sortField, "SortOrder:", sortOrder);
      //Setup query based on search text, sortField, sortOrder, and if moving to different page
      let query = firestore.collection("workorders").limit(tableViewSettings.resultsPerPage)
      if (searchTextToUse) {
        query = query.where('search_fields', 'array-contains', searchTextToUse);
      }

      switch (tableViewSettings.filter) {
        case 'complete':
          query = query.where('completed','==',true);
          break;
        case 'incomplete':
          query = query.where('completed','==',false);
          break;
        default:
          query = query.orderBy("completed","asc");
          break;
      }

      
      
      if (sortField === 'priority_level') {
        //query = query.orderBy("completed");
        //query = query.where('completed_date','==',null);
        
        query = query.orderBy(sortField, sortOrder);
        query = query.orderBy("date_needed", 'desc');
        
      } else { 
        query = query.orderBy(sortField, sortOrder);
      }
      
      if (movePage === "next" || !movePage) {
      //Moving to next page or getting first page
        if (startAfter) {
          query = query.startAfter(startAfter);
        }
      } else {
      //Moving to Previous Page
        query = query.endBefore(firstDocRef).limitToLast(tableViewSettings.resultsPerPage)
      }
      refs = await query.get();
      
      if (refs.docs[refs.docs.length -1] ) {
        setLastDocRef(refs.docs[refs.docs.length -1]);
      } 
      
      if (refs.docs[0]) {
        setFirstDocRef(refs.docs[0]);
      } else {
        //no results on this page, set First Doc to Last Doc of previous page for moving backwards at the end
        setFirstDocRef(lastDocRef);
      }
      
     
      refs.forEach(function(doc) {
        results.push({id: doc.id, ...doc.data()})
      });

      } catch(error) {
        console.log("Error getting documents: ", error);
      }
        
      setData(results);

      let page = currentPage;
      if (params.page) {
        page = params.page;
      }
      
      if (results.length < tableViewSettings.resultsPerPage) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }

      
      if (movePage === 'next') {
        page = currentPage+1;
      }
      if (page*tableViewSettings.resultsPerPage === totalItems) {
        setNextDisabled(true);
      }

      if (movePage === 'next') {
        setPrevDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage + 1 );
      }

      if (movePage === 'previous') {
        if (currentPage === 2) {
          setPrevDisabled(true);
        }
        setNextDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage - 1 );
      }
     
      setLoading(false);

    
  };

  const { current: fetchWorkorders } = useRef(fetch);

  const subscribeToTotalWorkorders = () => {
    unsubscribeFromTotalWorkorders = firestore.doc("counters/workorders").onSnapshot((doc) => {
      if (doc.data()) {
        console.log("Total Workorders",doc.data().count);
        setTotalItems(doc.data().count);
      } else {
        setTotalItems(0);
      }
      
    })
  }

  const { current: sub } = useRef(subscribeToTotalWorkorders);
  //const { current: unsub } = useRef(unsubscribeFromTotalWorkorders);
  
  useEffect(() => {
    console.log("useEffect for tableViewSettings change",tableViewSettings);
    let params = tableParams;
    params.page = 1;
    setCurrentPage(1);
    setPrevDisabled(true);
    fetch(params, searchText);
  },[tableViewSettings]);
  
  useEffect(() => {
      //console.log("Running useEffect",data);

      //generate safety reminders. But only max once per day.
      let today = moment().format("DD-MM-YYYY");
      if (localStorage.lastRunSafetyReminders != today) {
        callSafetyRemindersFunction()
          .then(res => {
            console.log(res.data);
            if (res.data.workordersGenerated && res.data.workordersGenerated.length > 0) {
              fetchWorkorders();
              message.success("New Safety Reminders have been created!");
             
            }
            localStorage.lastRunSafetyReminders = today;

          })
          .catch(error => {
            console.log(error);
            message.error("There was an error generating Safety Reminders");
          });
      }
    
      sub();
     
      //fetchWorkorders();
      return () => {
        unsubscribeFromTotalWorkorders();
      }

    
  },[sub,fetchWorkorders])


  useEffect(() => {
    if (currentPage*tableViewSettings.resultsPerPage === totalItems) {
      console.log("disabling next");
      setNextDisabled(true);
    }
  },[totalItems])

  const columns = [
    {
      title: 'Equipment',
      dataIndex: 'equipment_name',
      width: 150,
      sorter: true,
      render: (text, record) => { if (record.equipment && record.equipment.picture) {
          return (<div><Avatar src={record.equipment.picture.thumbUrl} /> {record.equipment_name}</div>) 
          } else {
            
            return (<div><Avatar /> {record.equipment_name}</div>) ;
        }
      },
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
      
    },
    {
      title: 'Unit #',
      
      
      width: 50,
      render: (text, record) => { if (record.equipment) {
        return record.equipment.unit_number
      }},
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
   
    {
      title: 'Ordered',
      dataIndex: 'order_date',
      sorter: true,
      width: 85,
      render: (text, record) => moment(record.order_date.toDate()).format("MMM DD/YY"),
      onCell: (record) => onCellClick(record),
      className: "equipment-cell"
    },
    {
     
      title: "P",
      width:30,
      dataIndex: 'priority_level',
      sorter: true,
      
      onCell: (record) => onCellClick(record),
      className: "equipment-cell centered-cell",
      render: (text, record) => {
        if (record.completed_date) {
          return "";
        }
        switch (record.priority_level) {
          case '30':
            return (<Badge color='green' />);
          case '20':
            return (<Badge color='yellow' />);
          case '10':
            return (<Badge color='red' />);
          default:
            return "";

        } 
      }
    },
    {
      title: 'Needed',
      dataIndex: 'date_needed',
      sorter: true,
      //defaultSortOrder: 'ascend',
      width: 100,
      render: (text, record) => { 
        let date_text = "";
        if (record.date_needed) {
          date_text = moment(record.date_needed.toDate().setHours(0,0,0,0)).from(new Date().setHours(0, 0, 0, 0));
          if (date_text === 'a few seconds ago') {
            date_text = 'today';
          }
        }
        if (record.completed_date && record.date_needed) {
          date_text = moment(record.date_needed.toDate()).format("MMM DD/YY");
        }
        return date_text;
      },
      onCell: (record) => onCellClick(record),
      className: "equipment-cell "
    }, 
    {
      title: 'Completed',
      dataIndex: 'completed_date',
      
      width: 100,
      render: (text, record) => { return (record.completed_date? <Tag icon={<CheckCircleOutlined />} color="success">{moment(record.completed_date.toDate()).format("MMM DD/YY")}</Tag> : null)},
      
      onCell: (record) => onCellClick(record),
      className: "equipment-cell completed-column"
    },
    {
      title: 'Details',
      render: (text, record) => { 
          if ( record.completed) { 
            return record.description_of_work_completed;
          } else {
            if (record.is_safety_reminder) {
              
              let description = null;
              if (record.request_description != 'Safety Due') {
                description = record.request_description;
              }
              if (record.completed) {
                description = record.description_of_work_completed;
              }
              return (<div>
                        <Tag color="warning"><SafetyOutlined/> Safety Due</Tag>
                        {description}
                      </div>)
            } else {
              return record.request_description;
            }
            
          }
      },
      width: 400,
      onCell: (record) => onCellClick(record),
      className: "equipment-cell truncated",
      ellipsis: true
      
    },
   {
      title: 'Action',
      width: 60,
      className: 'no-print',
      
      render: (text, record) => {
        return (
          <Popconfirm
            title={"Delete Workorder for " + record.equipment_name + "?"}
            onConfirm={() => deleteEntry(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        )
      }
    }
  ];

    const deleteEntry = (workorder) => {
      console.log("workorder",workorder);
      
      //Delete Database Entry
      firestore.collection("workorders").doc(workorder.id).delete()
      .then(() => {
        console.log("Document successfully deleted!");
        message.success('Workorder for ' + workorder.equipment_name + ' Deleted');
       
        //Delete Pictures
        if (workorder.invoices && workorder.invoices.length > 0) {
          workorder.invoices.forEach(invoice => {
            storage.ref().child(invoice.picture.imageName).delete()
            .then( () => {
              console.log("Deleted Image Successfully")
            })
            .catch((error) => {
              console.log("Delete image failed",error);
            })
          
          storage.ref().child(invoice.picture.thumbName).delete()
            .then( () => {
              console.log("Deleted Thumbnail Successfully")
            })
            .catch((error) => {
              console.log("Delete thumb failed",error);
            })
          }) //loop
        } //if
       
          

        
        fetch();
      }).catch(function(error) {
          console.error("Error removing document: ", error);
          message.error('Error deleting Equipment ');
      });
    }
    
    const cancel = (e) => {
      console.log(e);
      
    }

    const handleTableChange = (pag, filters, sorter) => {
      //const pager = { ...pagination };
      //pager.current = pag.current;
      setCurrentPage(1);
      setPrevDisabled(true);
      //setPagination({...pager},
        fetch({
         /*  results: pag.pageSize,
          page: pag.current, */
          page: 1,
          
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
          
        },searchText)
        
      //  );
      
      
      
    };

    
  
    

    const onTextSearch = debounce((value) => {
           console.log("onKeystroke",value);
           setCurrentPage(1);
           setPrevDisabled(true);
           setSearchText(value, setCurrentPage(1,fetch(tableParams,value)) );
           //fetch(tableParams,value);
    },200)

    let totals = null;
    if ((!searchText) && sortBy != 'priority_level') {
      totals = (currentPage-1) * tableViewSettings.resultsPerPage + 1 + " to " + ((totalItems < currentPage * tableViewSettings.resultsPerPage) ? totalItems : currentPage * tableViewSettings.resultsPerPage ) + " of " + totalItems + " Total Entries";
    }

    if (tableViewSettings.filter === 'incomplete') {
      totals = "Showing only incomplete"
    }

    if (tableViewSettings.filter === 'complete') {
      totals = "Showing only complete"
    }

    const printButtonChild = <Button  key='print' className='no-print' onClick={() => {setPrintPage(true)}}><PrinterOutlined /></Button>;
      let printButton = (<Tooltip key='printtooltip' className='no-print' title="Print Workorder List" >
                          {printButtonChild}  
                        </Tooltip>);
      //don't render tooltip when printing
      if (printPage) {
        printButton = printButtonChild;
      }

    useEffect(() => {
      if (printPage) {
        window.print();
        setPrintPage(false);
      }
    },[printPage]);
  


    return (
        <Content style={{}}>
          <Breadcrumb className="no-print">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Workorders</Breadcrumb.Item>
            
          </Breadcrumb>
       
        
        <Content className="site-layout-background">
          
       
            <PageHeader
            className="site-page-header-responsive"
            avatar={{icon: <ToolOutlined />}}
            ghost={false}
            onBack={() => window.history.back()}
            title="Workorders"
            
            backIcon={false} 
            extra={[
               
              <Row gutter={[8,8]} key="extra_row">
                <Col lg={7} sm={24}>
                <Select style={{width: 140}} defaultValue={tableViewSettings.filter} onChange={(value) => setTableViewSettings((p) => { return {...p, filter: value}})}>
                    <Select.Option value='all'>Show All</Select.Option>
                    <Select.Option value='incomplete'>Show Incomplete</Select.Option>
                    <Select.Option value='complete'>Show Complete</Select.Option>
                   
                  </Select>
                </Col>
                <Col lg={9} sm={24} key="search">
                    <Search
                    placeholder="search by name or unit"
                    block="true"
                    className="no-print"
                    //style={{ width: 200 }}
                    key="search"
                    onChange={(e) => onTextSearch(e.target.value)}
                  />
                </Col>
                <Col lg={6} sm={24} key="create_new">
                  <Button key="/" className="no-print" type="primary" block="true" onClick={() => {history.push("/workorders/new")}}>
                  <PlusOutlined />Create New
                  </Button>
                </Col>
                <Col lg={2} sm={24}>
                  {printButton}
                </Col>
               
              </Row>
              
            ]}
            >
            
            </PageHeader>

            <Table
              columns={columns}
              className="ant-table fixed"
              //scroll={{x: 'calc(100vh - 4em)'}}
              scroll={{ x: 'max-content' }}
              rowKey={record => record.id}
              sortDirections={['ascend']}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              size="small"
              
            />
            <Row gutter={[8,8]} align="middle" justify="end" style={{marginTop: '16px'}} >
              <Col >
                <span>
                  {totals}
                </span>
              </Col>
              <Col >
                <Space>
                  <Button disabled={prevDisabled} key="previous" type="default" onClick={() => fetch(tableParams, searchText, "previous")}>
                      <LeftOutlined />Previous
                    </Button>
                  
                  <Button disabled={nextDisabled} key="next" type="default" onClick={() => fetch(tableParams, searchText, "next")}>
                      <RightOutlined />Next
                  </Button>
                  <Select style={{width: 110}} defaultValue={tableViewSettings.resultsPerPage} onChange={(value) => setTableViewSettings((p) => { return {...p, resultsPerPage: value}})}>
                    <Select.Option value={20}>20 / page</Select.Option>
                    <Select.Option value={50}>50 / page</Select.Option>
                    <Select.Option value={100}>100 / page</Select.Option>
                  </Select>
                </Space>
              </Col> 
                
                
              
            </Row>

        </Content>
            
        </Content>
    )
}

export default ListWorkorders
