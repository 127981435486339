import React from "react";
import { Form, Input, Button, Col, Row, AutoComplete } from "antd";
import { MinusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from 'react';
import { firestore } from '../firebase'
import { debounce } from '../shared/utilities'



const PartsSubForm = (props) => {

  let incrementPartCount = () => null;
  let decrementPartCount = () => null;

  if (props.incrementPartCount) {
     incrementPartCount = props.incrementPartCount;
     decrementPartCount = props.decrementPartCount;
  }

  const layout = {
      lg: 5,
      md: 5,
      sm: 24
  };

  const [partsSearchResults, setPartsSearchResults] = useState([]);
  const rules = [{ required: true }];
  const {form} = props;

  
  const onSearch = debounce((searchText) => {
    let searchResults = [];
    if (searchText) {
        searchText = searchText.toLowerCase();
        console.log("Searching");
        firestore.collection('parts')
        /* .orderBy('description_lowercase', "asc")
        .startAt(searchText)
        .endAt(`${searchText}\uf8ff`) */
        .where('search_fields', 'array-contains', searchText)
        .limit(5)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach(function(doc) {
                const record = doc.data();
                searchResults.push({value: record.description, 
                                    key: record.part_id, 
                                    label: (
                                            <div
                                            style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            }}
                                        >
                                            <span>
                                            {record.description}
                                           
                                            </span>
                                            <span style={{fontStyle: "italic", fontSize: "8pt"}}>{record.serial_number}</span>
                                            
                                        </div>  
                                    ), 
                                    part: {description: record.description, serial_number: record.serial_number || null, note: record.note || null, vendor: record.vendor || null, part_id: record.part_id } })
            });
            setPartsSearchResults(searchResults);
        })
    } else {
        setPartsSearchResults([]);
    }
  },200);

  
  const onSelect = (data,option,index) => {
    let values = form.getFieldsValue();
    console.log("Values:",values);
    values.parts[index] = option.part;
    form.setFieldsValue(values);
  };

 

 

  return (
      
      <div>
      <Row>
        <Col lg={8} sm={24} md={8}><h4>Description</h4></Col>
        <Col {...layout}><h4>Serial Number</h4></Col>
        <Col {...layout}><h4>Vendor</h4></Col>
        <Col {...layout}><h4>Note</h4></Col>
      </Row>
      <Form.List name="parts">
        {(fields, { add, remove }) => {
          /**
           * `fields` internal fill with `name`, `key`, `fieldKey` props.
           * You can extends this into sub field to support multiple dynamic fields.
           */
          return (
            <div>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[{lg:0,sm:0}]}>
                  <Col lg={8} md={8} sm={24}>
                    <Form.Item
                      className="no-space-form-item"
                      wrapperCol={{span: 24}}
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                      rules={rules}
                    >
                    <AutoComplete
                        options={partsSearchResults}
                        //onChange={() => setRowSearching(e)}
                        onSelect={(data,option) => onSelect(data,option,index)}
                        onSearch={(text) => onSearch(text,index)}
                        placeholder="description"
                        style={{minWidth: '164px'}}
                    />
                    </Form.Item>
                  </Col>
                  <Col {...layout}>
                    <Form.Item
                      className="no-space-form-item"
                      wrapperCol={{span: 24}}
                      name={[field.name, "serial_number"]}
                      fieldKey={[field.fieldKey, "serial_number"]}
                      
                    >
                      <Input placeholder="serial number" />
                    </Form.Item>
                  </Col>
                  <Col {...layout}>
                    <Form.Item
                      className="no-space-form-item"
                      wrapperCol={{span: 24}}
                      name={[field.name, "vendor"]}
                      fieldKey={[field.fieldKey, "vendor"]}
                      
                    >
                      <Input placeholder="vendor" />
                    </Form.Item>
                  </Col>
                  <Col {...layout}>
                    <Form.Item
                      className="no-space-form-item last-part"
                      wrapperCol={{span: 24}}
                      name={[field.name, "note"]}
                      fieldKey={[field.fieldKey, "note"]}
                      
                      
                      
                    >
                      <Input placeholder="note" />
                    </Form.Item>
                  </Col>
                  <Col flex="none">
                    <MinusCircleOutlined
                      className="dynamic-delete-button no-print"
                      style={{marginLeft: '5px'}}
                      onClick={() => {
                        remove(field.name);
                        decrementPartCount();
                      }}
                    />
                  </Col>
                 
                </Row>
              ))}
              <Form.Item wrapperCol={{span:24}}>
                <Button
                  type="dashed"
                  className="no-print"
                  onClick={() => {
                   {/*  let values = form.getFieldsValue();
                    values.parts.push({serial_number: null, vendor:null, note:null});
                    
                    form.setFieldsValue(values); */}
                    add({serial_number: null, vendor: null, note: null });
                    incrementPartCount();
                  }}
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <PlusOutlined /> Add part
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
      </div>

     
  
  );
};

export default PartsSubForm;
