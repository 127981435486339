import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, DatePicker, Upload, message, Modal, Tooltip, Select, Tabs, List, Switch, Checkbox, Card, Tag } from 'antd';
import { useHistory, useParams, useLocation, Prompt } from 'react-router-dom'
import {SaveOutlined, CloseOutlined, LoadingOutlined, ToolOutlined, CarOutlined, AppstoreAddOutlined, CheckCircleOutlined, PrinterOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp, fb, storage } from '../../firebase'
import cuid from 'cuid'
import moment from 'moment';
import PartsSubForm from '../../PartsSubForm/PartsSubForm'
import { keepTrying, convertToSearchFields, updateWorkorders } from '../../shared/utilities'
import './EquipmentForm.css'






function EquipmentForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const { Option } = Select;
    const { id } = useParams();
    const location = useLocation();
    const [title, setTitle] = useState('New Equipment');
    const [equipmentThumbnail, setEquipmentThumbnail] = useState({icon: <CarOutlined />})
    const [unit_number, setUnitNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [formChanged, setFormChanged] = useState(false);
    const [originalPicture, setOriginalPicture] = useState(null);
    const [partsCount, setPartsCount] = useState(0);
    const [printPage, setPrintPage] = useState(false);
    //const [parts, setParts] = useState([]);
    const [workordersCount, setWorkordersCount] = useState(0);
    const [workorders,setWorkorders] = useState([]);
    const [filterWorkorders,setFilterWorkorders] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const [reminderDetailsVisible,setReminderDetailsVisible] = useState(false);
    

    const handleCancel = () => setPreviewVisible(false);

    const toggleWorkorderFilter = () => {
      if (filterWorkorders) {
        getAssociatedWorkorders(false);
        setFilterWorkorders(false);
      } else {
        getAssociatedWorkorders(true);
        setFilterWorkorders(true);
      }
    }



    const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    
    
    
  };

   const handleChange = ({ fileList }) => {
    //console.log("Filelist",fileList) 
    setFileList( fileList );
    setFormChanged(true);
   };

   const onTabChanged = (key) => {
    console.log(key);
    setActiveTab(key);
  }


    
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
      

      useEffect(() => {
        //Get document from Firebase if id exists as a param
        if (id) {
          
          if (location.state) {
            //console.log("location.state",location.state);
            const data = location.state;
            setTitle(data.name);
            setUnitNumber(data.unit_number);
            setOriginalPicture(data.picture);
            if (data.picture) {
              setEquipmentThumbnail({src: data.picture.thumbUrl})
            }
            setReminderDetailsVisible(data.issue_safety_reminder);
            if (data.parts) {
              setPartsCount(data.parts.length);
            }
            
                        
            delete data.id;
            if (data.date_in_service) {
              data.date_in_service = moment.unix(data.date_in_service.seconds);
            }
            if (data.next_safety_due_date) {
              data.next_safety_due_date = moment.unix(data.next_safety_due_date.seconds);
            }
            
            data.created = moment.unix(data.created.seconds);
            
            form.setFieldsValue(data);
            if (data.picture && data.picture.imageName) {
              setFileList([{
                uid: data.picture.imageName,
                name: data.name,
                status: 'done',
                url: data.picture.imageUrl
              }])
            } 
          } else {
            //console.log("Fetching from Firestore");
            firestore.collection("equipment").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                
                if (data.date_in_service) {
                  data.date_in_service = moment(data.date_in_service.toDate());
                }
                
                data.created = moment(data.created.toDate());
                
                form.setFieldsValue(data);
                setOriginalPicture(data.picture);
                setTitle(data.name);
                setUnitNumber(data.unit_number);
                setReminderDetailsVisible(data.issue_safety_reminder);
                //console.log("Setting Field Values:",data);
                 if (data.picture) {
                  
                  
                  setFileList([{
                    uid: data.picture.imageName,
                    name: data.name,
                    status: 'done',
                    url: data.picture.imageUrl
                  }])
                } 
                

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                    message.error("Could not load Equipment");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
          }
          

        getAssociatedWorkorders();
        

        } //if (id)
      },[id,form,location.state])

     
      const incrementPartCount = () => { setPartsCount(prevPartsCount => prevPartsCount + 1)}
      const decrementPartCount = () => { setPartsCount(prevPartsCount => prevPartsCount - 1)}

      const getAssociatedWorkorders = async (showOnlyIncomplete = false) => {
        let results = [];
        setLoading(true);
        let query = firestore.collection("workorders").where('equipment_id', '==', id);
        if (showOnlyIncomplete) {
          console.log("showOnlyIncomplete");
          query = query.where("completed", "==",false).orderBy("completed_date","desc");

        } else {
          console.log("show all");
          query = query.orderBy("order_date", "desc");
        }
        
        try {
          const refs = await query.get();
        
        refs.forEach(function(doc) {
          results.push({id: doc.id, ...doc.data()})
        });
        setLoading(false); 
        setWorkorders(results);
        setWorkordersCount(results.length);
      } catch(error) {
        message.error(error.message);
        console.log(error.message);
      }
        //console.log("Equipment",results);
      }
      
      const onFinish = values => {
        //console.log('Values before picture:', values);
        values.search_fields = convertToSearchFields([values.name, values.unit_number]);
        
       
        //console.log("FileList",fileList);
        //Handle Picture
        if (fileList.length > 0) {
          if (fileList[0].response) {
            values.picture = fileList[0].response;
          }
        } else {
          values.picture = null;
        }

        //Check if user changed image. If so we need to delete it from Firebase Storage
        if (originalPicture && originalPicture.imageName) {
          let deleteOldPicture = false;
          //User deleted picture without replacing it
          if (!fileList[0]) {
            //console.log("Deleted Picture");
            deleteOldPicture = true;
          }
          //user replaced existing  photo
          if (fileList[0] && fileList[0].uid != originalPicture.imageName) {
            console.log("Replaced Picture");
            deleteOldPicture = true;
          }
          if (deleteOldPicture) {
            //console.log("We need to delete picture:", originalPicture.imageName, originalPicture.thumbName);
            storage.ref().child(originalPicture.imageName).delete()
            .then( () => {
              console.log("Deleted Picture Successfully")
            })
            .catch((error) => {
              console.log("Delete picture failed",error);
            })
            storage.ref().child(originalPicture.thumbName).delete()
            .then( () => {
              console.log("Deleted Picture Successfully")
            })
            .catch((error) => {
              console.log("Delete picture failed",error);
            })
          }
        }

        //Handle safety reminder
        if (values.issue_safety_reminder) {
          
          let today = moment();
          let nextDueDate = values.next_safety_due_date;
          while (nextDueDate < today) {
            //make sure date is in the future
            nextDueDate = nextDueDate.add(parseInt(values.repeat_interval), "months");
          }
          values.next_safety_due_date = nextDueDate;
          let dateToIssueReminder =  nextDueDate.clone().subtract(30, "days");
          values.date_to_issue_reminder = fb.firestore.Timestamp.fromDate(dateToIssueReminder.toDate());
        } else {
          values.next_safety_due_date = null;
        }

        if (values.date_in_service) {
          values.date_in_service = fb.firestore.Timestamp.fromDate(values.date_in_service.toDate());
        }
        if (values.next_safety_due_date) {
          values.next_safety_due_date = fb.firestore.Timestamp.fromDate(values.next_safety_due_date.toDate());
        }

        if (!values.condition) {
          values.condition = null;
        }

        if (!values.charging_system) {
          values.charging_system = null;
        }

        if (!values.status) {
          values.status = null;
        }

        
        

        //Handle Parts
        //values contains form data to be submitted
        let partsDocRefs = [];
        let partsPromises = [];
        
        if (values.parts) {
          values.parts.forEach((part,index) => {
             //If this part was hand typed (ie NOT populated from the autocomplete) we need to create a part in the parts collection and insert the id into this part
            if (!part.part_id) {
              //Get a reference to a new parts document
              partsDocRefs[index] = firestore.collection('parts').doc();
              values.parts[index].part_id = partsDocRefs[index].id;
              part.search_fields = convertToSearchFields([part.description]);
              part.created = fb.firestore.Timestamp.fromDate(new Date());
              partsPromises.push(partsDocRefs[index].set({...part}));
            } 

          }) //end loop
          
          if (partsPromises.length > 0) {
            Promise.all(partsPromises)
            .then(() => {
              console.log("Parts promises finished");
              sendToFirebase(values)
            })
            .catch((e) => {
              console.log("Error creating Parts",e);
          });
          } else {
            sendToFirebase(values);
          }
          
        } else {
          sendToFirebase(values);
        }
       

        
       
        
        
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
        //check if error is related to parts
        errorInfo.errorFields.forEach(field => {
          if (field.name[0] === 'parts') {
            setActiveTab("parts");
          }
        })
        
      };

      const sendToFirebase = (values) => {
        //console.log("Values before posting to Firebase",values)
        //create parts_ids array
        if (values.parts ) {
          let parts_ids = values.parts.map(part => part.part_id);
          values.parts_ids = [...parts_ids];
        }
        
        if (id) {
          //Update existing equipment
          firestore.collection('equipment').doc(id).set({...values}, {merge: true})
          .then(function() {
            setFormChanged(false);
            message.success('Equipment Updated!');
            history.push('/equipment');
            updateWorkorders(values,id);
          })
          .catch(function(error) {
              console.error("Error updating document: ", error);
          });

        } else {
          //Add new equipment
          values.created = serverTimestamp();
          firestore.collection('equipment').add({...values})
          .then(function() {
            setFormChanged(false);
            message.success(`Added Equipment: ${values.name}`);
            history.push('/equipment');
          })
          .catch(function(error) {
              console.error("Error creating document: ", error);
          });
        }

      }

      
      const onValuesChange = () => {
        console.log("OnValuesChange triggered");
        //console.log("New Values:", form.getFieldValue("parts"));
        
        setFormChanged(true);
      }

      const  getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      
      const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          message.error('Image must smaller than 10MB!');
        }
        return isJpgOrPng && isLt10M;
      }

      const customUpload = async ({ onError, onSuccess, file, onProgress }) => {
        
        const metadata = {
            contentType: file.type
        }
        const storageRef = storage.ref();
        const unique_id = cuid(); //a unique name for the image
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const imageName = 'images/' + unique_id + suffix; 
        const resizedImageName = 'images/' + unique_id + '_2500x2500' + suffix;
        const thumbImageName = 'images/' + unique_id + '_200x200' + suffix;
        const imgFile = storageRef.child(imageName);
        try {
          const uploadTask = imgFile.put(file, metadata);
          //check upload progress
          uploadTask.on('state_changed', function progress(snapshot) {
            const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            //console.log('snapshot percent', percent)
            onProgress({percent: percent })
            //start searching for resized image once upload is complete
            if (percent === 100) {
              //show uploaded file
              onSuccess({});
              
              const resizedStorageRef = storage.ref().child(resizedImageName);
              const thumbStorageRef = storage.ref().child(thumbImageName);
              const resizePromises = [keepTrying(10, resizedStorageRef),keepTrying(10, thumbStorageRef)];
              setResizing(true);
              Promise.all(resizePromises).then((results) => {
                const [resizedUrl,thumbUrl] = results;
                //console.log("Resized to 2500x2500",resizedUrl);
                //console.log("Resized to 200x200",thumbUrl);
                onSuccess({imageUrl: resizedUrl, imageName: resizedImageName, thumbUrl: thumbUrl, thumbName: thumbImageName});
                setResizing(false);

              })
            }
          });
          
        }
        catch(e) {
          console.log(e);
          onError(e);
        }
      };

      const uploadButton = (
        <div>
        
        <div className="ant-upload-text">Upload</div>
      </div>
      );



      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" disabled={resizing} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }

      const printButtonChild = <Button  key='print' className='no-print' onClick={() => {setPrintPage(true)}}><PrinterOutlined /></Button>;
      let printButton = (<Tooltip key='printtooltip' className='no-print' title="Print Current Tab" >
                          {printButtonChild}  
                        </Tooltip>);
      //don't render tooltip when printing
      if (printPage) {
        printButton = printButtonChild;
      }
      
      useEffect(() => {
        if (printPage) {
          window.print();
          setPrintPage(false);
        }
      },[printPage]);
      
    return (
        <div>
        <Prompt when={formChanged} message="Unsaved Changes.  Press Ok to discard edits. Press Cancel to keep editing." />
        <Breadcrumb className="no-print">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Equipment</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                avatar={equipmentThumbnail}
                title={title}
                subTitle={unit_number ? 'Unit # '+ unit_number : null}
                extra={[
                    saveButton,
                    <Button key="close" className="no-print" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                    printButton
                ]}
                
                >
                
                </PageHeader>
                <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="medium"
            initialValues={{ 
              name: null,
              owner: null,
              serial_number: null,
              vin_number: null,
              plate_number: null,
              registered_weight: null,
              year: null,
              make: null,
              model: null,
              oil_type: null,
              description: null,
              date_in_service: null,
              condition: null,
              status: null,
              unit_number: null,
              decal: null,
              weight: null,
              fuel_type: null,
              hydraulic_oil_type: null,
              engine_model: null,
              transmission_model: null,
              tire_size: null,
              picture: null,
              charging_system: null,
              issue_safety_reminder: false,
              next_safety_due_date: null,
              repeat_interval: "6",
              parts: []}}
            >
            
            
            <Tabs activeKey={activeTab} onChange={onTabChanged}>
              <TabPane tab={<span><CarOutlined style={{marginRight: 5}}/>Details</span>} key="details">
            
                <Row>
                    <Col sm={24} md={12} lg={12} >
                        <Form.Item
                            label="Name"
                            name="name"
                            className="tight-form-item"
                            rules={[
                            {
                                required: true,
                                message: 'Please input a name',
                            },
                            
                            ]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Category" name="category" rules={[{required: true, message: 'Please select a category'}]}>
                          <Select
                            placeholder="Select Category"
                            
                            allowClear
                          >
                            <Option value="ATV">ATV</Option>
                            <Option value="Carrier">Carrier</Option>
                            <Option value="Drill Rig">Drill Rig</Option>
                            <Option value="Equipment Misc.">Equipment Misc.</Option>
                            <Option value="Equipment Powered">Equipment Powered</Option>
                            <Option value="Forklift">Forklift</Option>
                            <Option value="Skid Steer">Skid Steer</Option>
                            <Option value="Trailer (Medium)">Trailer (Medium)</Option>
                            <Option value="Trailer (Heavy)">Trailer (Heavy)</Option>
                            <Option value="Truck (Medium)">Truck (Medium)</Option>
                            <Option value="Truck (Heavy)">Truck (Heavy)</Option>
                         </Select>
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Unit #" name="unit_number" rules={[{required: true, message: 'Please enter a Unit #'}]}><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Owner" name="owner" ><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Equipment Serial #" name="serial_number"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Vehicle VIN#" name="vin_number"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Plate #" name="plate_number"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Registered Weight" name="registered_weight"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Year" name="year"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Make" name="make"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Model" name="model"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Oil Type" name="oil_type"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Charging System" name="charging_system">
                          <Select placeholder="Select Charging System" allowClear>
                            <Option value="N/A">N/A</Option>
                            <Option value="6v">6v</Option>
                            <Option value="12v">12v</Option>
                            <Option value="24v">24v</Option>
                          </Select>
                        </Form.Item>
                       
                        <Form.Item className="tight-form-item" label="Description" name="description"><Input.TextArea autoSize={{ minRows: 2}} /></Form.Item>
                    </Col>
                    <Col sm={24} md={12} lg={12} >
                       
                        <Form.Item name="picture" label="Picture">
                        
                        <Upload
                          customRequest={customUpload}
                          listType="picture-card"
                          fileList={fileList}
                          
                          onPreview={handlePreview}
                          onChange={handleChange}
                          beforeUpload={beforeUpload}
                        >
                          {fileList.length >= 1 ? null : uploadButton}
                          
                          {/* {fileList.length > 0 ? <img src={fileList[0].thumbUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                        </Upload>
                        
                        
                              
                        </Form.Item>
                        
                        {resizing ? <Form.Item label=" " colon={false}><span><LoadingOutlined /> Resizing...</span></Form.Item>  : null}
                        
                        <Modal
                          visible={previewVisible}
                          width="80%"
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                              
                        <Form.Item className="tight-form-item" label="Date In Service" name="date_in_service" ><DatePicker format="MMMM DD YYYY" /></Form.Item>
                        <Form.Item className="tight-form-item" label="Status" name="status" >
                          <Select placeholder="Select Status" allowClear>
                            <Option value="New">New</Option>
                            <Option value="Used">Used</Option>
                            <Option value="Sold">Sold</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Condition" name="condition">
                          <Select placeholder="Select Condition" allowClear>
                            <Option value="10 Good">Good</Option>
                            <Option value="20 Fair">Fair</Option>
                            <Option value="30 Poor">Poor</Option>
                            <Option value="40 Out of Order">Out of Order</Option>
                          </Select>
                        </Form.Item>
                        
                        <Form.Item className="tight-form-item" label="Decal" name="decal"><Input /></Form.Item>
                        
                        <Form.Item className="tight-form-item" label="Weight (kg)" name="weight"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Fuel Type" name="fuel_type">
                          <Select
                            placeholder="Select Fuel Type"
                            
                            allowClear
                          >
                          <Option value="N/A">N/A</Option>
                            <Option value="diesel">diesel</Option>
                            <Option value="gas">gas</Option>
                            <Option value="propane">propane</Option>
                            <Option value="mixed">mixed</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Hydraulic Oil Type" name="hydraulic_oil_type"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Engine Model" name="engine_model"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Transmission Model" name="transmission_model"><Input /></Form.Item>
                        <Form.Item className="tight-form-item" label="Tire Size" name="tire_size"><Input /></Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="tight-form-item"  name="issue_safety_reminder" valuePropName="checked">
                          <Checkbox onChange={() => setReminderDetailsVisible(prevState => !prevState)}>Issue Safety Reminder</Checkbox>
                        </Form.Item>
                        { reminderDetailsVisible ? 
                          ( <Row>
                            <Col sm={{span:24}} md={{span:24}} lg={{span:16, offset:8}}>
                            <Card>
                              <Form.Item labelCol={{span: 12}} wrapperCol={{span: 12 }} className="tight-form-item" 
                                         label="Remind 30 Days Before" 
                                         name="next_safety_due_date"
                                         
                                         rules={[
                                          {
                                            required: reminderDetailsVisible,
                                            message: 'Select Next Due Date',
                                          },
                                        ]} 
                              >
                                <DatePicker style={{width: '100%'}} placeholder="next due date" format="MMMM DD YYYY" />
                              </Form.Item>
                              <Form.Item  labelCol={{span: 12}} wrapperCol={{span: 12 }} className="tight-form-item" label="Repeat Every" name="repeat_interval">
                                <Select placeholder="Repeat Every">
                                  <Option value='6'>6 Months</Option>
                                  <Option value='12'>12 Months</Option>
                                </Select>
                              </Form.Item>
                            </Card>
                            </Col>
                          </Row>
                            
                          )    
                        : null}
                        
                    </Col>
                </Row>
               

                </TabPane>
                <TabPane tab={<span><AppstoreAddOutlined style={{marginRight: 5}}/>Parts ({partsCount})</span>} key="parts">
                  <PartsSubForm form={form} incrementPartCount={incrementPartCount} decrementPartCount={decrementPartCount}  />
                </TabPane>
                
                <TabPane tab={<span><ToolOutlined style={{marginRight: 5}}/>Workorders ({workordersCount})</span>} key="workorders">
                <Row style={{marginBottom: 10}}>
                  <Switch checkedChildren="Showing Incomplete" unCheckedChildren="Showing All"  onChange={toggleWorkorderFilter} />
                </Row>
                  <List
                   
                    itemLayout="horizontal"
                    bordered
                    loading={loading}
                    rowKey={item => item.id}
                    style={{width: "100%"}}
                    dataSource={workorders}
                    renderItem={(item,key) => (
                      <List.Item  key={key} >
                        <List.Item.Meta
                          //avatar={<Avatar src={item.picture ? item.picture.thumbUrl : null} />}
                    title={<Row><Col span={8}>{moment(item.order_date.toDate()).format("MMMM DD YYYY")}</Col><Col span={16} style={{textAlign:'right'}}>{item.completed_date ? (<Tag icon={<CheckCircleOutlined />} color="success">{moment(item.completed_date.toDate()).format("MMM DD/YY")}</Tag>) : null}</Col></Row> }
                          description={ item.completed ? item.description_of_work_completed : item.request_description}
                          key={item.id}
                          
                          className="equipment-list-item"
                          //description={item.category + (item.unit_number ? " Unit #" + item.unit_number : "")   }
                          onClick={() => history.push({
                                                        pathname: `/workorders/${item.id}`,
                                                        state: item
                                                      })}
                        />
                      </List.Item>
                    )}
                  />
                </TabPane>
                

            
            </Tabs>
            </Form>
        </Content>
            
        </div>
    )
}

export default EquipmentForm


