import React, {useEffect, useState, useRef} from 'react'
import {Breadcrumb, Layout, PageHeader, Button, Avatar, message, Popconfirm, Table, Input, Row, Col, Space} from 'antd'
import { PlusOutlined, AppstoreAddOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import { firestore, storage } from '../../firebase'
import {debounce} from '../../shared/utilities'
import './ListParts.css'





function ListParts() {
  
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  const resultsPerPage = 20;
    
  //state
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ searchText, setSearchText] = useState('');
  const [ tableParams, setTableParams] = useState({});
  const [ lastDocRef, setLastDocRef] = useState(null);
  const [ firstDocRef, setFirstDocRef] = useState(null);
  const [ prevDisabled, setPrevDisabled] = useState(true);
  const [ nextDisabled, setNextDisabled] = useState(false);
  const [ currentPage, setCurrentPage] = useState(1);
  const [ totalItems, setTotalItems] = useState(null);
  

  let unsubscribeFromTotalItems = null;

    
  const onCellClick = (record) => {
    return {
      onClick: event => { history.push({
        pathname: `/parts/${record.id}`,
        state: record
      })}
    }
  }

  const fetch = async (params = {}, searchTextToUse = null, movePage = null) => {
      
    if (searchTextToUse) {
      searchTextToUse = searchTextToUse.toLowerCase();
    }
    
    setTableParams(params);
    let sortField = params.sortField ? params.sortField : "description";
    setLoading(true);
    
    try {
      let refs = null;
      let startAfter = '';
      //let limit = 10;
      if (movePage === "next") {
        startAfter = lastDocRef;
      }

      //Moving to next page or getting first page
      if (movePage === "next" || !movePage) {
        if (searchTextToUse) {
         refs = await firestore.collection("parts")
                                .where('search_fields', 'array-contains', searchTextToUse)                      
                                .orderBy(sortField)
                                .limit(resultsPerPage)
                                .startAfter(startAfter)
                                .get()
        } else {
          refs = await firestore.collection("parts")
                                .orderBy(sortField)
                                .limit(resultsPerPage)
                                .startAfter(startAfter)
                                .get()
        }

      //Moving to Previous Page
      } else {
        if (searchTextToUse) {
          refs = await firestore.collection("parts")
                                .where('search_fields', 'array-contains', searchTextToUse)                      
                                .orderBy(sortField)
                                .endBefore(firstDocRef.data()[sortField])
                                .limitToLast(resultsPerPage)
                                .get()
        } else {
          refs = await firestore.collection("parts")
                                .orderBy(sortField)
                                .endBefore(firstDocRef.data()[sortField])
                                .limitToLast(resultsPerPage)
                                .get()
        }
      }
      
      let results = [];
      setLastDocRef(refs.docs[refs.docs.length -1]);
      setFirstDocRef(refs.docs[0]);
     
      refs.forEach(function(doc) {
        results.push({id: doc.id, ...doc.data()})
      });
        
      setData(results);
      
      if (results.length < resultsPerPage) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }

      if ((currentPage+1)*resultsPerPage === totalItems) {
        setNextDisabled(true);
      }

      if (movePage === 'next') {
        setPrevDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage + 1 );
      }

      if (movePage === 'previous') {
        if (currentPage === 2) {
          setPrevDisabled(true);
        }
        setNextDisabled(false);
        setCurrentPage(prevCurrentPage => prevCurrentPage - 1 );
      }
     
      setLoading(false);

    } catch(error) {
      console.log("Error getting documents: ", error);
    }
  };

  const { current: fetchItems } = useRef(fetch);

  const subscribeTototalItems = () => {
    unsubscribeFromTotalItems = firestore.doc("counters/parts").onSnapshot((doc) => {
      if (doc.data()) {
        console.log("Total Part",doc.data().count);
        setTotalItems(doc.data().count);
      }
      
    })
    
  }

  const { current: sub } = useRef(subscribeTototalItems);
  //const { current: unsub } = useRef(unsubscribeFromtotalItems);
  
  useEffect(() => {
      sub();
     
      fetchItems();
      return () => {
        unsubscribeFromTotalItems();
      }

    
  },[sub,fetchItems])


  const columns = [
    
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      width: '20%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: '20%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      width: '20%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
      
    },
    {
      title: 'Note',
      dataIndex: 'note',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
      title: 'Action',
      
      className: 'no-print',
      render: (text, record) => {
        return (
          <Popconfirm
            title={"Delete Part: " + record.description + "?"}
            onConfirm={() => deleteEntry(record.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        )
      }
    }
  ];

    const deleteEntry = (id) => {
      
      
      //Delete Database Entry
      firestore.collection("parts").doc(id).delete()
      .then(() => {
        console.log("Document successfully deleted!");
        message.success('Part Deleted');
       
        
        fetch();
      }).catch(function(error) {
          console.error("Error removing document: ", error);
          message.error('Error deleting Part ');
      });
    }
    
    const cancel = (e) => {
      console.log(e);
      
    }

    const handleTableChange = (pag, filters, sorter) => {
      //const pager = { ...pagination };
      //pager.current = pag.current;
      setCurrentPage(1);
      setPrevDisabled(true);
      //setPagination({...pager},
        fetch({
          results: pag.pageSize,
          page: pag.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
          
        },searchText)
        
      //  );
      
      
      
    };

    
  
    

    const onTextSearch = debounce((value) => {
           console.log("onKeystroke",value);
           setCurrentPage(1);
           setPrevDisabled(true);
           setSearchText(value, setCurrentPage(1,fetch(tableParams,value)) );
           //fetch(tableParams,value);
    },250)
  


    return (
        <div>
          <Breadcrumb className="no-print">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Parts</Breadcrumb.Item>
            
          </Breadcrumb>
       
        
          <Content className="site-layout-background">
          
       
            <PageHeader
            className="site-page-header-responsive"
            avatar={{icon: <AppstoreAddOutlined />}}
            ghost={false}
            onBack={() => window.history.back()}
            title="Parts"
            
            backIcon={false} 
            extra={[
               
              <Row gutter={[8,8]} key="extra_row">
                <Col lg={14} sm={24} key="search">
                    <Search
                    placeholder="search by description"
                    block="true"
                    //style={{ width: 200 }}
                    key="search"
                    onChange={(e) => onTextSearch(e.target.value)}
                  />
                </Col>
                <Col lg={10} sm={24} key="create_new">
                  <Button key="/" className="no-print" type="primary" block="true" onClick={() => {history.push("/parts/new")}}>
                  <PlusOutlined />Create New
                  </Button>
                </Col>
              </Row>
              
            ]}
            >
            
            </PageHeader>

            <Table
              columns={columns}
              className="ant-table"
              //scroll={{x: 'calc(100vh - 4em)'}}
              scroll={{ x: 'max-content' }}
              rowKey={record => record.id}
              sortDirections={['ascend']}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              size="small"
              
            />
            <Row gutter={[8,8]} align="middle" justify="end" style={{marginTop: '16px'}} >
              <Col >
                <span>
                  { searchText ? null : (currentPage-1)*resultsPerPage+1 + " to " + (totalItems < currentPage*resultsPerPage ? totalItems : currentPage*resultsPerPage ) + " of " + totalItems + " Total Entries"}
                </span>
              </Col>
              <Col >
                <Space className="no-print">
                  <Button disabled={prevDisabled} key="previous" type="default" onClick={() => fetch(tableParams, searchText, "previous")}>
                      <LeftOutlined />Previous
                    </Button>
                  
                  <Button disabled={nextDisabled} key="next" type="default" onClick={() => fetch(tableParams, searchText, "next")}>
                      <RightOutlined />Next
                    </Button>
                </Space>
              </Col> 
                
                
              
            </Row>

        </Content>
            
        </div>
    )
}

export default ListParts
