import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';



const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
  appId: process.env.REACT_APP_APP_ID_DEV,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV
};

//console.log('measurementID:',process.env.REACT_APP_MEASUREMENT_ID_DEV);
 const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY_PROD,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
  databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
  projectId: process.env.REACT_APP_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_APP_ID_PROD,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD
}; 

 /* const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig; 
 */
firebase.initializeApp(prodConfig);
firebase.analytics();

export const firestore = firebase.firestore();
export const storage = firebase.storage()
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firestoreDate = firebase.firestore.Timestamp;
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fb = firebase;
export const getCurrentUser = () => {
  return new Promise((res, rej) => {
    const unsubscribe = auth.onAuthStateChanged(curUser => {
      unsubscribe();
      res(curUser);
    }, rej);
  });
};

