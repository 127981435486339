import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, message,  Select, Checkbox } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp, functions } from '../../firebase'
import {convertToSearchFields} from '../../shared/utilities'
import moment from 'moment'





const createUser = functions.httpsCallable('createUser');
const updateUser = functions.httpsCallable('updateUser');


function UserForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const location = useLocation();
    const [loading,setLoading] = useState(false);
    const [title, setTitle] = useState('New User');
    const [formChanged, setFormChanged] = useState(false);
    const {Option} = Select;
    
    

 
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };

    const tailLayout = {wrapperCol: { offset: 8, span: 16}}
    
      useEffect(() => {
        //Get document from Firebase if id exists as a param
        console.log("id",id);
        if (id) {
          if (location.state) {
            console.log("getting data from location.state");
            setTitle(location.state.first_name);
            const data = location.state;
            delete data.id;
            
            form.setFieldsValue(data);
          } else {
            console.log("Fetching from Firestore");
            firestore.collection("users").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                form.setFieldsValue(data);
                setTitle(data.first_name);
              } else {
                console.log("No such document!");
                message.error("Could not load Part. It does not exist!");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Part");
            });
          }
       
        } //if (id)

        
      },[id,form,location.state])

     
           
      const onFinish = values => {
        console.log('Values at beginning of onFinish:', values);
        values.search_fields = convertToSearchFields([values.first_name, values.last_name]);
        
       
        
        console.log("Values before posting to Firebase",values)
        //values contains form data to be submitted
        
        sendToFirebase(values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        console.log("Values before posting to Firebase",values)
        setLoading(true);
        if (id) {
         values.id = id;
         updateUser(values)
            .then(function(resp) {
              //Display success
              console.log(resp.data.result);
              setLoading(false);
              message.success(`Updated User: ${values.first_name + " " + values.last_name}`);
              history.push('/users');
            })
            .catch(function(error) {
              const code = error.code;
              const message = error.message;
              console.log(code,message);
              message.error(message);
              //Display error
            });
          /*  //Update existing parts
          firestore.collection('parts').doc(id).set({...values}, {merge: true})
          .then(function() {
            console.log("Document successfully updated!");
            message.success('Part Updated!');
            history.push('/parts');
          })
          .catch(function(error) {
              console.error("Error updating document: ", error);
          }); */

        } else {
            createUser(values)
            .then(function(resp) {
              //Display success
              console.log(resp.data.result);
              message.success(`Created User: ${values.first_name + " " + values.last_name}`);
              history.push('/users');
            })
            .catch(function(error) {
              const code = error.code;
              const message = error.message;
              console.log(code,message);
              message.error(message);
              //Display error
            });
          
          
        }

      }

      
      const onValuesChange = () => {
        console.log("OnValuesChange triggered");
        setFormChanged(true);
      }

      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" loading={loading} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }
      
    return (
        <div>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Users</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'User: ' + title}
                extra={[
                    saveButton,
                    <Button key="close" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="medium"
            initialValues={{ 
              first_name: null,
              last_name: null,
              display_name: null,
              email: null,
              password: null,
              disabled: false,
              role: "user"
            }}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            className=""
                            rules={[{required: true, message: 'Please input a first name'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            className=""
                            rules={[{required: true, message: 'Please input a last name'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            className=""
                            rules={[{required: true, type: 'email', message: 'Please input a valid email address'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                            {
                                required: true,
                                min: 6,
                                message: 'Please input a valid password! Min 6 characters',
                            },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item className="" label="Role" name="role">
                        
                          <Select
                            placeholder="Select Role"
                            
                            
                          >
                            <Option value="user">user</Option>
                            <Option value="admin">admin</Option>
                            
                          </Select>
                        </Form.Item>
                        <Form.Item label="Disabled" name="disabled" valuePropName="checked" > 
                          <Checkbox  />
                        </Form.Item>
                        
                       
                    </Col>
                   
                </Row>
               
            </Form>
        </Content>
            
        </div>
    )
}

export default UserForm


