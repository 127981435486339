import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, message, Divider, Select, List, Avatar } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp, fb, storage } from '../../firebase'
import InvoicesSubForm from '../InvoicesSubForm/InvoicesSubForm'
import {convertToSearchFields} from '../../shared/utilities'
import moment from 'moment'
import cuid from 'cuid'
import Moment from 'moment';

import './PartForm.css'





function PartForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const location = useLocation();
    const [title, setTitle] = useState('New Part');
    const [fileList, setFileList] = useState([]);
    const [formChanged, setFormChanged] = useState(false);
    const [equipment, setEquipment] = useState([]);
    const [workorders, setWorkorders] = useState([]);
    const [saveDisabled, setSaveDisabled] = useState(false);

    const disableSave = (isDisabled) => {
      setSaveDisabled(isDisabled);
    }
 
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
    
      useEffect(() => {
        //Get document from Firebase if id exists as a param
        console.log("id",id);
        if (id) {
          if (location.state) {
            console.log("getting data from location.state");
            setTitle(location.state.description);
            const data = location.state;
            delete data.id;
            data.created = Moment.unix(data.created.seconds);
            if (data.invoices) {
              data.invoices.forEach((invoice, index) => {
                if (invoice.date) {
                  invoice.date = Moment.unix(invoice.date.seconds);
                }
                if (invoice.picture && invoice.picture.thumbUrl) {
                  setFileList(prevFileList =>  {
                    let newFileList = [...prevFileList];
                    newFileList[index] = [{
                      uid: invoice.picture.imageName,
                      name: "Invoice Photo",
                      status: 'done',
                      url: invoice.picture.imageUrl
                    }];
                   
                    return newFileList;
                  } );
                }
              })
            }
            

            form.setFieldsValue(data);
          } else {
            console.log("Fetching from Firestore");
            firestore.collection("parts").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                data.created = Moment(data.created.toDate());
                form.setFieldsValue(data);
                setTitle(data.description);
              } else {
                console.log("No such document!");
                message.error("Could not load Part. It does not exist!");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Part");
            });
          }
        getAssociatedEquipment();
        getAssociatedWorkorders();
        } //if (id)

        
      },[id,form,location.state])

     
      const getAssociatedEquipment = async () => {
        let results = [];
        const refs = await firestore.collection("equipment")
                                .where('parts_ids', 'array-contains', id)                      
                                .orderBy("name")
                                .get()
        
        refs.forEach(function(doc) {
          results.push({id: doc.id, ...doc.data()})
        });
          
        setEquipment(results);
        //console.log("Equipment",results);
      }
      const getAssociatedWorkorders = async () => {
        let results = [];
        const refs = await firestore.collection("workorders")
                                .where('parts_ids', 'array-contains', id)                      
                                .orderBy("order_date")
                                .get()
        
        refs.forEach(function(doc) {
          results.push({id: doc.id, ...doc.data()})
        });
          
        setWorkorders(results);
        //console.log("Equipment",results);
      }
      
      const onFinish = values => {
        console.log('Values at beginning of onFinish:', values);
        values.search_fields = convertToSearchFields([values.description]);
        values.created = fb.firestore.Timestamp.fromDate(new Date());
        if (values.invoices) {
          values.invoices.forEach(invoice => {
            if (invoice.date) {
              invoice.date = fb.firestore.Timestamp.fromDate(invoice.date.toDate());
            }

            if (invoice.picture) {
              if (invoice.picture.file) {
                invoice.picture = invoice.picture.file.response;
              }
            }

            if (typeof(invoice.picture) === 'undefined') {
              invoice.picture = null;
            }
          })
        }
        
        //console.log("Values before posting to Firebase",values)
        //values contains form data to be submitted
        sendToFirebase(values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        console.log("Values before posting to Firebase",values)
        
        if (id) {
          //Update existing parts
          firestore.collection('parts').doc(id).set({...values}, {merge: true})
          .then(function() {
            console.log("Document successfully updated!");
            message.success('Part Updated!');
            history.push('/parts');
          })
          .catch(function(error) {
              console.error("Error updating document: ", error);
          });

        } else {
          //Add new parts
          values.created = serverTimestamp();
          firestore.collection('parts').add({...values})
          .then(function() {
            console.log("Document successfully created!");
            message.success(`Added Part: ${values.description}`);
            history.push('/parts');
          })
          .catch(function(error) {
              console.error("Error creating document: ", error);
          });
        }

      }

      
      const onValuesChange = () => {
        console.log("OnValuesChange triggered");
        setFormChanged(true);
      }

      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" disabled={saveDisabled} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }
      
    return (
        <div>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Parts</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'Part: ' + title}
                extra={[
                    saveButton,
                    <Button key="close" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="medium"
            initialValues={{ 
              description: null,
              serial_number: null,
              vendor: null,
              note: null,
              invoices: []}}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                        <Form.Item
                            label="Description"
                            name="description"
                            className="tight-form-item"
                            rules={[
                            {
                                required: true,
                                message: 'Please input a description',
                            },
                            
                            ]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item className="tight-form-item" label="Serial Number" name="serial_number"><Input /></Form.Item>
                       
                    </Col>
                    <Col sm={24} lg={12} >
                      <Form.Item className="tight-form-item" label="Vendor" name="vendor" ><Input /></Form.Item>
                      <Form.Item className="tight-form-item" label="Note" name="note" ><Input /></Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                      <List
                        header={<div><h4>{equipment.length} Equipment with this Part</h4></div>}
                        itemLayout="horizontal"
                        bordered
                        style={{width: "100%"}}
                        dataSource={equipment}
                        renderItem={item => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={<Avatar src={item.picture ? item.picture.thumbUrl : null} />}
                              title={item.name}
                              className="equipment-list-item"
                              description={item.category + (item.unit_number ? " Unit #" + item.unit_number : "")   }
                              onClick={() => history.push({
                                                            pathname: `/equipment/${item.id}`,
                                                            state: item
                                                          })}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col sm={24} lg={12} >
                      <List
                          header={<div><h4>{workorders.length} Workorders with this Part</h4></div>}
                          itemLayout="horizontal"
                          bordered
                          style={{width: "100%"}}
                          dataSource={workorders}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                                //avatar={<Avatar src={item.picture ? item.picture.thumbUrl : null} />}
                                title={item.equipment_name + " - " + moment(item.order_date.toDate()).format("MMMM DD YYYY") }
                                className="equipment-list-item"
                                //description={item.category + (item.unit_number ? " Unit #" + item.unit_number : "")   }
                                onClick={() => history.push({
                                                              pathname: `/workorders/${item.id}`,
                                                              state: item
                                                            })}
                              />
                            </List.Item>
                          )}
                        />
                    </Col>
                </Row>

                <Divider orientation="left">Invoices</Divider>
                <Row>
                  <Col sm={24} lg={24}>
                    <InvoicesSubForm form={form} onChanged={() => setFormChanged(true)} fileList={fileList} disableSave={disableSave} />
                  </Col>
                  
                </Row>
                

            </Form>
        </Content>
            
        </div>
    )
}

export default PartForm


