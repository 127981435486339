import {firestore} from '../firebase'
import { message } from 'antd';

export const debounce = (func, wait) => {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        const later = function() {
        timeout = null;
        func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
 };


 function delay(t, v) {
    return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
    });
  }
  
  export const keepTrying = (triesRemaining, storageRef) => {
    if (triesRemaining < 0) {
      return Promise.reject('out of tries');
    }
  
    return storageRef.getDownloadURL().then((url) => {
      return url;
    }).catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          return delay(2000).then(() => {
            return keepTrying(triesRemaining - 1, storageRef)
          });
        default:
          console.log(error);
          return Promise.reject(error);
      }
    })
  }

  export const convertToSearchFields = (fields) => {
    
    let searchFields = [];
   
    fields.forEach(field => {
      if (field) {
        const lowerCaseText = field.toLowerCase();
        let strChunks = [];
        //if field contains multiple words, split them up
        if (lowerCaseText.indexOf(' ') > 0) {
            strChunks = lowerCaseText.split(' ');
            strChunks.forEach(chunk => {
                searchFields.push(...createKeywords(chunk))
            })
        }
        
        searchFields.push(...createKeywords(lowerCaseText));
        //remove duplicates
        searchFields = [...new Set(searchFields)];
      }  
      
       
    })
    return searchFields;
}


const createKeywords = name => {
  const arrName = [];
  let curName = '';
  name.split('').forEach(letter => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
}


export const updateWorkorders = async (equipment,id) => {
  console.log("Equipment",equipment);
  let equipmentRefs = await firestore.collection("workorders").where("equipment_id","==",id).get();
     equipmentRefs.forEach(function(doc) {
        console.log("We need to update workorder:", doc.id);
        firestore.collection('workorders').doc(doc.id).set({equipment: {category: equipment.category,
                                                                        name: equipment.name,
                                                                        picture: equipment.picture,
                                                                        unit_number: equipment.unit_number},
                                                                        equipment_name: equipment.name,
                                                                        search_fields: convertToSearchFields([equipment.name,equipment.unit_number]),}, {merge: true})
          .then(function() {
            
          })
          .catch(function(error) {
              console.error("Error updating workorder: ", error);
          });      

     });
}

export const updateEquipmentParts = async (workorderParts, equipment_id) => {
  try {
    let equipmentRef = await firestore.collection("equipment").doc(equipment_id).get();
    let equipmentParts = equipmentRef.data().parts;
    if (!equipmentParts) {
      equipmentParts = [];
    }
    //console.log("Equipment parts ", equipmentParts);
    //console.log("workorder parts:",workorderParts);
    let equipmentPartIds = equipmentRef.data().parts_ids;
    if (!equipmentPartIds) {
      equipmentPartIds = [];
    }
    //console.log("Equipment Part IDs",equipmentPartIds);  
    let partsToAdd = [];
    workorderParts.forEach(part => {
        if (!equipmentPartIds.includes(part.part_id)) {
          partsToAdd.push({...part});
          equipmentPartIds.push(part.part_id);

        } 
    })
    if (partsToAdd.length > 0) {
      console.log("Parts to Add:",partsToAdd.length);
      const newEquipmentParts = [...equipmentParts, ...partsToAdd];
      
      let equipmentRef = await firestore.collection("equipment").doc(equipment_id).set({parts: newEquipmentParts, parts_ids: equipmentPartIds},{merge: true});
    }

  } catch(error) {
    message.error(error);
    console.log(error);
  }
  

}